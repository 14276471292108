import type { OpenApiSchema } from "@/types/open-api";

const endpointsToKeep = [
  "/api/v1/access/agents/{id}/conversations",
  "/api/v1/access/agents/{id}/conversations/{cid}",
  "/api/v1/access/conversations/{id}/messagesse",
  "/api/v1/access/conversations/{id}/message",
  "/api/v1/access/conversations/{id}/update-context",
];

export const filterOpenApiEndpoints = (openApiJson: OpenApiSchema): OpenApiSchema => {
  const filteredOpenApi: OpenApiSchema = { ...openApiJson, paths: {} };

  for (const path of endpointsToKeep) {
    if (openApiJson.paths[path]) {
      filteredOpenApi.paths[path] = openApiJson.paths[path];
    }
  }

  return filteredOpenApi;
};
