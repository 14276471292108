import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";

export const ChatHistoryDialogDownloadSelectBtn = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <IconButton
      size="medium"
      className="ml-2"
      variant="tertiary"
      icon={<Icons.Download className="size-6" />}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
