import { Icons } from "@/components/ui/icons";
import { voteMessage } from "@/data/message";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import type { Conversation, ConversationMessagesResponse, Message } from "@/types/conversation";
import type { InfiniteData } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useCreateMessageBookmark } from "@/data/mutations/bookmarks/useCreateUserMessageBookmark";
import { useDeleteUserMessageBookmark } from "@/data/mutations/bookmarks/useDeleteUserMessageBookmark";
import type { Agent } from "@/types/agent";
import useIsChatWithPagination from "../hooks/useIsChatWithPagination";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { PlayMessageVoice } from "./PlayMessageVoice";

type VoteMessageProps = {
  messageId: Message["_id"];
  conversationId: Conversation["_id"] | undefined;
  agentId: Agent["_id"] | undefined;
  vote: Message["vote"];
  message?: Message["text"];
  isBookmark: Message["isBookmark"];
};

export const VoteMessage = ({ messageId, conversationId, agentId, vote, message, isBookmark }: VoteMessageProps) => {
  const queryClient = useQueryClient();
  const [, copy] = useCopyNotify();
  const { isPreview } = useChatContext();
  const { mutate: createBookmark, isPending: isPendingCreateBookmark } = useCreateMessageBookmark();
  const { mutate: deleteBookmark, isPending: isPendingDeleteBookmark } = useDeleteUserMessageBookmark();
  const withPagination = useIsChatWithPagination();

  if (!conversationId || !agentId) {
    return;
  }

  const onThumbClick = async (vote: 1 | 0) => {
    const prevMessages = queryClient.getQueryData<InfiniteData<ConversationMessagesResponse>>(
      conversationMessagesKeys.id({ conversationId }, withPagination)
    );
    const prevMessagesDeepCopy = JSON.parse(JSON.stringify(prevMessages)) as InfiniteData<ConversationMessagesResponse>;

    try {
      queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
        conversationMessagesKeys.id({ conversationId }, withPagination),
        prev => {
          if (!prev) {
            return undefined;
          }
          return {
            pageParams: [...prev.pageParams],
            pages: prev.pages.map(page => {
              return {
                ...page,
                messages: page.messages.map(message => {
                  if (message._id === messageId) {
                    return { ...message, vote };
                  }
                  return message;
                }),
              };
            }),
          };
        }
      );
      await voteMessage({
        messageId,
        vote,
        agentId,
        conversationId,
      });
    } catch (error) {
      queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
        conversationMessagesKeys.id({ conversationId }, withPagination),
        prevMessagesDeepCopy
      );
    }
  };

  const onBookmarkIconClick = () => {
    if (isPendingCreateBookmark || isPendingDeleteBookmark) {
      return;
    }

    if (isBookmark) {
      deleteBookmark({ messageId, agentId, conversationId });
    } else {
      createBookmark({ messageId, agentId, conversationId });
    }
  };

  // TODO: remove when voice is ready for prod
  const isProd = !!import.meta.env.VITE_ENV && import.meta.env.VITE_ENV === "prod";

  return (
    <div className="ml-[-4px] mr-auto mt-auto flex items-center gap-2 pl-[54px]">
      {message && (
        <Tooltip>
          <TooltipTrigger onClick={() => copy(message)}>
            <Icons.CopyChat className="mt-0.5 w-[22px] cursor-pointer text-neutral-500 hover:text-neutral-600" />
          </TooltipTrigger>
          <TooltipContent align="end" className="translate-x-3">
            Copy
          </TooltipContent>
        </Tooltip>
      )}

      <Tooltip>
        <TooltipTrigger onClick={() => onThumbClick(1)}>
          <Icons.ThumbUp
            className={cn(
              "size-[26px] cursor-pointer p-1 text-neutral-600 hover:text-neutral-500",
              vote === 1 ? "text-neutral-600" : "text-neutral-400"
            )}
          />
        </TooltipTrigger>
        <TooltipContent>Good response</TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger onClick={() => onThumbClick(0)}>
          <Icons.ThumbDown
            className={cn(
              "size-[26px] cursor-pointer p-1 text-neutral-600 hover:text-neutral-500",
              vote === 0 ? "text-neutral-600" : "text-neutral-400"
            )}
          />
        </TooltipTrigger>
        <TooltipContent>Bad response</TooltipContent>
      </Tooltip>

      {!isPreview && (
        <Tooltip>
          <TooltipTrigger>
            <div onClick={onBookmarkIconClick}>
              {isBookmark ? (
                <Icons.BookmarkFilled className="size-[26px] cursor-pointer p-1 text-neutral-600 hover:text-neutral-500" />
              ) : (
                <Icons.BookmarkOutlined className="size-[26px] cursor-pointer p-1 text-neutral-400 hover:text-neutral-500" />
              )}
            </div>
          </TooltipTrigger>
          <TooltipContent>{isBookmark ? "Remove from bookmark" : "Add to bookmark"}</TooltipContent>
        </Tooltip>
      )}
      {messageId && !isProd && <PlayMessageVoice messageId={messageId} />}
    </div>
  );
};
