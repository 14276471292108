import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";

type Props = {
  ascendindDescription: string;
  descendingDescription: string;
  onClickAscending: () => void;
  onClickDescending: () => void;
  isSelectedAscending?: boolean;
  isSelectedDescending?: boolean;
};

export const StatsLeadsSortByDropdown = ({
  ascendindDescription,
  descendingDescription,
  onClickAscending,
  onClickDescending,
  isSelectedAscending,
  isSelectedDescending,
}: Props) => {
  const isSelected = isSelectedAscending || isSelectedDescending;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
        <IconButton
          icon={<Icons.ArrowUpDown />}
          variant="tertiary"
          size="tiny"
          className={cn({
            "bg-primary-100": isSelected,
          })}
        />
      </DropdownMenuTrigger>

      <DropdownMenuContent onClick={e => e.stopPropagation()} align="start">
        <DropdownMenuItem
          className="flex items-center gap-1.5 text-xs"
          onClick={e => {
            e.preventDefault();
            onClickAscending();
          }}
        >
          <Icons.Check
            className={cn("mr-2.5", {
              "opacity-0": !isSelectedAscending,
            })}
          />
          {ascendindDescription}
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex items-center gap-1.5 text-xs"
          onClick={e => {
            e.preventDefault();
            onClickDescending();
          }}
        >
          <Icons.Check
            className={cn("mr-2.5", {
              "opacity-0": !isSelectedDescending,
            })}
          />
          {descendingDescription}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
