import { Icons } from "@/components/ui/icons";
import { Switch } from "@/components/ui/switch";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { DiscordDialog } from "./DiscordDialog";
import type { DiscordAgent, ExternalAgent } from "@/types/agent";
import { usePauseExternalAgent } from "@/data/mutations/external-agents/usePauseExternalAgent";
import { useStartExternalAgent } from "@/data/mutations/external-agents/useStartExternalAgent";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { DeleteExternalAgentDialog } from "@/components/dialogs/DeleteExternalAgentDialog/DeleteExternalAgentDialog";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { IntegrationStatus } from "../IntegrationStatus";
import { Button } from "@/components/ui/button";
import { LinkButton } from "@/components/ui/link-button";
import { IconButton } from "@/components/ui/icon-button";
import { ExternalAgentPlatform } from "@/types/origin";

// you have to append a discordClientId to this URL
const discordOAuthUrlPartial = import.meta.env.VITE_DISCORD_OAUTH_URL as string;

export const DiscordTile = ({ externalAgents }: { externalAgents: ExternalAgent[] | undefined }) => {
  const discordAgent = externalAgents?.find(
    (agent): agent is DiscordAgent => agent.platform === ExternalAgentPlatform.DISCORD
  );

  const [isDialogOpen, setShowDialog] = useState(false);
  const [, copy] = useCopyNotify();

  const { getValues: getAgentFormValues } = useAgentFormContext();
  const agentId = getAgentFormValues("_id");
  // mutations
  const { mutate: pauseExternalAgent, isPending: isPausing } = usePauseExternalAgent();
  const { mutate: startExternalAgent, isPending: isStarting } = useStartExternalAgent();

  const isActive = !!discordAgent?.active;
  const discordOAuthUrl = discordOAuthUrlPartial + (discordAgent?.platformAttributes.discordClientId ?? "");

  const handleCheckedChange = (checked: boolean) => {
    if (!discordAgent) {
      if (checked) {
        setShowDialog(true);
      }
      //toast.error("There was an error while retrieving the Discord bot.");
      return;
    }
    const externalAgentId = discordAgent._id;
    checked ? startExternalAgent({ externalAgentId, agentId }) : pauseExternalAgent({ externalAgentId, agentId });
  };
  return (
    <>
      <div
        className={cn(
          "relative flex max-w-[30.25rem] flex-col justify-between gap-4 overflow-hidden rounded-md p-4",
          discordAgent ? "border border-neutral-300" : "shadow-integration"
        )}
      >
        <div className="flex gap-4">
          <Icons.DiscordCloud className="size-16" />
          <div className="flex w-full flex-col gap-4">
            <div className="flex items-center justify-between gap-4">
              <div className="flex gap-4">
                <span className="text-base font-bold">Discord</span>
                <IntegrationStatus status={discordAgent?.status} />
              </div>
              <Switch disabled={isPausing || isStarting} checked={isActive} onCheckedChange={handleCheckedChange} />
            </div>
            <p className="text-xs font-medium leading-relaxed">Deploy the agent to a Discord server.</p>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-1">
          {discordAgent ? (
            <>
              <span className="flex items-center gap-1 px-4 text-xs font-medium text-neutral-400">
                <Icons.CheckCircle className="text-success" /> Configured
              </span>
              <div className="ml-4 flex gap-4">
                <LinkButton className="gap-1 px-0 text-primary-400" asChild>
                  <a href={discordOAuthUrl} target="_blank" rel="noreferrer">
                    Add Bot to a Server <Icons.Arrow className="size-5 rotate-[135deg]" />
                  </a>
                </LinkButton>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton
                      variant="tertiary"
                      className="text-primary-400"
                      onClick={() => copy(discordOAuthUrl)}
                      icon={<Icons.Link2 className="size-4" />}
                    />
                  </TooltipTrigger>
                  <TooltipContent side="bottom">Copy "Add bot to a Server" link</TooltipContent>
                </Tooltip>
                <DeleteExternalAgentDialog
                  externalAgentId={discordAgent._id}
                  platform={ExternalAgentPlatform.DISCORD}
                />
              </div>
            </>
          ) : (
            <Button variant="tertiary" roundedType="rounded" size="tiny" onClick={() => setShowDialog(true)}>
              Configure
            </Button>
          )}
        </div>
      </div>
      <DiscordDialog isDialogOpen={isDialogOpen} setShowDialog={setShowDialog} />
    </>
  );
};
