import { format } from "date-fns";
import { useGetUser } from "@/data/queries/useGetUser";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import type { ConversationWithUsersDetails } from "@/types/conversation";
import { useState } from "react";
import { ShareChatHistoryConversationButtonDialog } from "@/components/ChatHistoryDialog/components/ShareChatHistoryConversationButtonDialog";
import { ChatHistoryPreviewConversationSummary } from "@/components/ChatHistoryPreview/components/ChatHistoryPreviewConversationSummary";

type Props = {
  conversationItem: {
    conversation: ConversationWithUsersDetails;
    searchMatches: number;
  };
  conversationUsers: {
    _id: string;
    name: string;
    avatar: string;
  }[];
  onOpen: () => void;
  renderHoverButtons?: () => React.ReactNode;
};

export const ChatHistoryDialogListItem = ({
  conversationItem,
  conversationUsers,
  onOpen,
  renderHoverButtons,
}: Props) => {
  const { user } = useGetUser();
  const {
    data: conversationMessagesData,
    isLoading,
    isPending,
  } = useGetConversationMessages({
    conversationId: conversationItem.conversation._id,
  });
  const [isHovered, setIsHovered] = useState(false);

  const conversationUser = conversationMessagesData?.messages[conversationMessagesData.messages.length - 1]?.user;
  const isUserMe = user?._id === conversationUser;
  const getUserName = () => {
    if (conversationUser) {
      return conversationUsers.find(user => user._id === conversationUser)?.name ?? "";
    }
    return isUserMe ? "Me" : "No info";
  };

  const getConversationSummary = () => {
    if (conversationItem.conversation.summary) {
      return conversationItem.conversation.summary.text;
    }

    return (
      conversationMessagesData?.messages?.[conversationMessagesData.messages.length - 1]?.text.slice(0, 150) ||
      "No data"
    );
  };

  return (
    <div
      className="min-h-[4.5rem] w-full cursor-pointer gap-1 rounded border border-solid border-cloud-dark px-3 py-2 text-xs text-gray-800 hover:bg-cloud-light"
      onClick={onOpen}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center justify-between gap-2">
        <ChatHistoryPreviewConversationSummary
          text={isPending || isLoading ? "Loading..." : getConversationSummary()}
        />

        {isHovered && (
          <>
            {renderHoverButtons && renderHoverButtons()}
            <div onClick={e => e.stopPropagation()}>
              <ShareChatHistoryConversationButtonDialog iconButton conversationId={conversationItem.conversation._id} />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-1 items-center gap-4 px-1 pt-2">
        {isLoading && isPending ? (
          "Loading..."
        ) : (
          <>
            <p className="text-left text-sm font-semibold">
              {format(conversationItem.conversation.updatedAt, "dd MMM yyyy HH:mm")}
            </p>
            <div className="text-sm text-gray-500">User: {getUserName()}</div>
            <div className="rounded-xl border border-solid border-primary-500 px-3 py-0.5 text-primary-500">
              {conversationMessagesData?.totalMessages || 0}
            </div>
            <p className="ml-auto text-xs font-medium text-neutral-400">
              {calculateTimeDifference(conversationItem.conversation.updatedAt)}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
