import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useGetUserAgents } from "@/data/queries/useGetUserAgents";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { AgentDeploymentsIcons } from "../AgentDeploymentsIcons/AgentDeploymentsIcons";

export const AgentsList = () => {
  const { data, isLoading } = useGetUserAgents();

  if (!data && isLoading) {
    return <LoadingSpinner className="grid size-full place-items-center" />;
  }

  return (
    <>
      <div className="pb-6 text-lg font-bold lg:border-b-[1.5px]">Agents</div>
      <Table>
        <TableHeader>
          <TableRow className="text-xs md:text-sm">
            <TableHead className="p-2 md:p-3">Name</TableHead>
            <TableHead className="p-2 md:p-3">Followers</TableHead>
            <TableHead className="p-2 md:p-3">Deployment</TableHead>
            <TableHead className="p-2 lg:px-3">Date</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.map(agent => (
            <TableRow key={agent._id}>
              <TableCell className="p-2 text-xs font-medium md:p-3">{agent.name}</TableCell>
              <TableCell className="p-2 text-xs text-slate-500 md:p-3">{agent.likes} followers</TableCell>
              <TableCell className="p-2 text-xs md:p-3">
                <div className="flex flex-row items-center gap-1.5">
                  <AgentDeploymentsIcons deployments={agent.deployments} />
                </div>
              </TableCell>
              <TableCell className="break-words p-2 text-xs text-slate-500 md:p-3">
                {calculateTimeDifference(agent.createdAt)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {/* <TableFooter className="h-12"></TableFooter> */}
      </Table>
    </>
  );
};
