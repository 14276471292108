import { AgentState } from "@/types/agent";
import { Origins } from "@/types/origin";
import { v4 as uuidv4 } from "uuid";
import type {
  AgentOverviewStats,
  AllAgentsStat,
  EngagementOverviewStat,
  ILeadGen,
  StatsDougnhnutChartData,
  UserLeadsStats,
} from "@/types/stats";
import { names, uniqueNamesGenerator } from "unique-names-generator";

export const statsMockBasicData: {
  title: string;
  value: number;
  trend: number | null | undefined;
  icon?: React.ReactNode;
  link?: {
    to: string;
    label: string;
  };
}[] = [
  {
    title: "Messages",
    value: 636,
    trend: 22,
  },
  {
    title: "Conversations",
    value: 55,
    trend: 8,
  },
  {
    title: "Views",
    value: 958,
    trend: 6,
  },
  {
    title: "Lead Generation",
    value: 36,
    trend: 45,
  },
];

export const statsMockTopAgentData = {
  agent: {
    _id: "1",
    creator: "1",
    isActive: true,
    agentType: "humanlike",
    name: "John Doe",
    avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
    contacts: [],
    blocked: [],
    blockedFrom: [],
    deleted: false,
    createdAt: "2021-09-01",
    updatedAt: "2021-09-01",
    state: AgentState.AGENT_READY,
    publishedToCommunity: true,
    externalKnowledgeIntegrations: {},
  },
  messages: {
    total: 636,
    trend: 22,
  },
  views: {
    total: 958,
    trend: 6,
  },
  satisfaction: 97,
};

export const statsMockEngagementData: EngagementOverviewStat[] = [
  {
    intervalStartDate: "2021-09-01",
    intervalEndDate: "2021-09-02",
    agents: 5,
    messages: 60,
    conversations: 7,
  },
  {
    intervalStartDate: "2021-09-02",
    intervalEndDate: "2021-09-03",
    agents: 3,
    messages: 49,
    conversations: 5,
  },
  {
    intervalStartDate: "2021-09-03",
    intervalEndDate: "2021-09-04",
    agents: 5,
    messages: 63,
    conversations: 10,
  },
  {
    intervalStartDate: "2021-09-04",
    intervalEndDate: "2021-09-05",
    agents: 5,
    messages: 64,
    conversations: 15,
  },
  {
    intervalStartDate: "2021-09-05",
    intervalEndDate: "2021-09-06",
    agents: 6,
    messages: 70,
    conversations: 18,
  },
  {
    intervalStartDate: "2021-09-06",
    intervalEndDate: "2021-09-07",
    agents: 4,
    messages: 55,
    conversations: 12,
  },
  {
    intervalStartDate: "2021-09-07",
    intervalEndDate: "2021-09-08",
    agents: 7,
    messages: 75,
    conversations: 20,
  },
  {
    intervalStartDate: "2021-09-08",
    intervalEndDate: "2021-09-09",
    agents: 6,
    messages: 72,
    conversations: 19,
  },
  {
    intervalStartDate: "2021-09-09",
    intervalEndDate: "2021-09-10",
    agents: 8,
    messages: 80,
    conversations: 22,
  },
  {
    intervalStartDate: "2021-09-10",
    intervalEndDate: "2021-09-11",
    agents: 7,
    messages: 78,
    conversations: 21,
  },
  {
    intervalStartDate: "2021-09-11",
    intervalEndDate: "2021-09-12",
    agents: 9,
    messages: 85,
    conversations: 25,
  },
  {
    intervalStartDate: "2021-09-12",
    intervalEndDate: "2021-09-13",
    agents: 8,
    messages: 82,
    conversations: 23,
  },
  {
    intervalStartDate: "2021-09-13",
    intervalEndDate: "2021-09-14",
    agents: 10,
    messages: 90,
    conversations: 27,
  },
  {
    intervalStartDate: "2021-09-14",
    intervalEndDate: "2021-09-15",
    agents: 9,
    messages: 88,
    conversations: 26,
  },
  {
    intervalStartDate: "2021-09-15",
    intervalEndDate: "2021-09-16",
    agents: 11,
    messages: 95,
    conversations: 30,
  },
];

export const statsMockAllAgentsData: AllAgentsStat[] = [
  {
    agent: {
      _id: "1",
      creator: "1",
      isActive: true,
      agentType: "humanlike",
      name: "John Doe",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-01",
      updatedAt: "2021-09-01",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 60,
    conversations: 10,
    views: 50,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "2",
      creator: "2",
      isActive: true,
      agentType: "humanlike",
      name: "Jane Smith",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/14.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-02",
      updatedAt: "2021-09-02",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 45,
    conversations: 9,
    views: 75,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "3",
      creator: "3",
      isActive: true,
      agentType: "humanlike",
      name: "Alice Johnson",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/15.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-03",
      updatedAt: "2021-09-03",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 55,
    conversations: 12,
    views: 95,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "4",
      creator: "4",
      isActive: true,
      agentType: "humanlike",
      name: "Bob Brown",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/16.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-04",
      updatedAt: "2021-09-04",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 40,
    conversations: 8,
    views: 90,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "5",
      creator: "5",
      isActive: true,
      agentType: "humanlike",
      name: "Charlie Davis",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/17.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-05",
      updatedAt: "2021-09-05",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 60,
    conversations: 13,
    views: 120,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "6",
      creator: "6",
      isActive: true,
      agentType: "humanlike",
      name: "Diana Evans",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/18.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-06",
      updatedAt: "2021-09-06",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 52,
    conversations: 11,
    views: 105,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "7",
      creator: "7",
      isActive: true,
      agentType: "humanlike",
      name: "Eve Foster",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/19.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-07",
      updatedAt: "2021-09-07",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 48,
    conversations: 10,
    views: 98,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "8",
      creator: "8",
      isActive: true,
      agentType: "humanlike",
      name: "Frank Green",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/20.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-08",
      updatedAt: "2021-09-08",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 58,
    conversations: 14,
    views: 135,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "9",
      creator: "9",
      isActive: true,
      agentType: "humanlike",
      name: "Grace Harris",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/21.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-09",
      updatedAt: "2021-09-09",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 62,
    conversations: 15,
    views: 155,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
];

export const statsMockAgentsOverviewData: AgentOverviewStats[] = [
  {
    agent: {
      _id: "1",
      creator: "1",
      isActive: true,
      agentType: "humanlike",
      name: "John Doe",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-01",
      updatedAt: "2021-09-01",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 50,
    messagesTrend: 37,
    conversations: 10,
    conversationsTrend: 24,
    satisfaction: 97,
    satisfactionTrend: 55,
    channels: [
      { channel: Origins.WEB, value: 30 },
      { channel: Origins.EMBEDDED, value: 30 },
      { channel: Origins.AGENT_SPACE, value: 30 },
      { channel: Origins.WORKSPACE, value: 30 },
      { channel: Origins.SLACK, value: 30 },
      { channel: Origins.WHATSAPP, value: 30 },
      { channel: Origins.INSTAGRAM, value: 30 },
    ],
  },
  {
    agent: {
      _id: "2",
      creator: "2",
      isActive: true,
      agentType: "humanlike",
      name: "Jane Smith",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/14.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-02",
      updatedAt: "2021-09-02",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 45,
    messagesTrend: 30,
    conversations: 9,
    conversationsTrend: 20,
    satisfaction: 95,
    satisfactionTrend: 50,
    channels: [
      { channel: Origins.WEB, value: 28 },
      { channel: Origins.EMBEDDED, value: 28 },
      { channel: Origins.AGENT_SPACE, value: 28 },
      { channel: Origins.WORKSPACE, value: 28 },
      { channel: Origins.SLACK, value: 28 },
      { channel: Origins.WHATSAPP, value: 28 },
      { channel: Origins.INSTAGRAM, value: 28 },
    ],
  },
  {
    agent: {
      _id: "3",
      creator: "3",
      isActive: true,
      agentType: "humanlike",
      name: "Alice Johnson",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/15.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-03",
      updatedAt: "2021-09-03",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 55,
    messagesTrend: 30,
    conversations: 12,
    conversationsTrend: 20,
    satisfaction: 98,
    satisfactionTrend: 50,
    channels: [
      { channel: Origins.WEB, value: 32 },
      { channel: Origins.EMBEDDED, value: 32 },
      { channel: Origins.AGENT_SPACE, value: 32 },
      { channel: Origins.WORKSPACE, value: 32 },
      { channel: Origins.SLACK, value: 32 },
      { channel: Origins.WHATSAPP, value: 32 },
      { channel: Origins.INSTAGRAM, value: 32 },
    ],
  },
  {
    agent: {
      _id: "4",
      creator: "4",
      isActive: true,
      agentType: "humanlike",
      name: "Bob Brown",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/16.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-04",
      updatedAt: "2021-09-04",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 40,
    messagesTrend: 30,
    conversations: 8,
    conversationsTrend: 20,
    satisfaction: 92,
    satisfactionTrend: 50,
    channels: [
      { channel: Origins.WEB, value: 25 },
      { channel: Origins.EMBEDDED, value: 25 },
      { channel: Origins.AGENT_SPACE, value: 25 },
      { channel: Origins.WORKSPACE, value: 25 },
      { channel: Origins.SLACK, value: 25 },
      { channel: Origins.WHATSAPP, value: 25 },
      { channel: Origins.INSTAGRAM, value: 25 },
    ],
  },
];

export const statsEmptyDoughnutChartData: StatsDougnhnutChartData = {
  datasets: [
    {
      data: [1],
      backgroundColor: ["#E0E0E0"],
    },
  ],
};

const getRandomizedAgentData = () => {
  const randomId = uuidv4();
  const randomName = uniqueNamesGenerator({ dictionaries: [names] });

  const getRandomAvatar = () => {
    const avatarIndex = Math.floor(Math.random() * 20) + 1;
    return `https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/${avatarIndex}.svg`;
  };

  return {
    _id: randomId,
    creator: "1",
    isActive: true,
    agentType: "humanlike",
    name: randomName,
    avatar: getRandomAvatar(),
    contacts: [],
    blocked: [],
    blockedFrom: [],
    deleted: false,
    createdAt: "2021-09-01",
    updatedAt: "2021-09-01",
    state: AgentState.AGENT_READY,
    publishedToCommunity: true,
    externalKnowledgeIntegrations: {},
  };
};

export const statsMockUserLeadsData: UserLeadsStats = {
  isLeadGenEnabled: true,
  leads: [
    {
      type: "DEFAULT",
      _id: "678fb96e7cc799a64093c181",
      conversationId: "678e42b57b3a9b18786eeb2e",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f008",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f009",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f00a",
          key: "user_request",
          value: "Requires a new website design.",
        },
        {
          _id: "67938eca73abc6f7c423f00c",
          key: "id",
          value: "678e42b57b3a9b18786eeb2e",
        },
      ],
      createdAt: "2025-01-14T15:12:46.536Z",
      updatedAt: "2025-01-21T15:12:46.536Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790dd48855bb1d29932a742",
      conversationId: "6790dd001ed45c26201023dc",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f00d",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f00e",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f00f",
          key: "user_request",
          value: "Is looking for a social media advertising campaign.",
        },
        {
          _id: "67938eca73abc6f7c423f011",
          key: "id",
          value: "6790dd001ed45c26201023dc",
        },
      ],
      createdAt: "2025-01-22T11:58:00.676Z",
      updatedAt: "2025-01-22T11:58:00.676Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790ddf60b89b26f99f1e22a",
      conversationId: "6790ddb11ed45c26201027ab",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f012",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f013",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f014",
          key: "user_request",
          value: "Wants to implement a CRM system for their business.",
        },
        {
          _id: "67938eca73abc6f7c423f016",
          key: "id",
          value: "6790ddb11ed45c26201027ab",
        },
      ],
      createdAt: "2025-01-15T12:00:54.461Z",
      updatedAt: "2025-01-22T12:00:54.461Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de650b89b26f99f1e231",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Is interested in increasing their brand's online visibility.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-16T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de650bcaca89b26f99f1e231423",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Needs help writing content for their website.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-16T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de650b89b26dasdasf99f1e231",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Is looking for SEO services to improve search rankings.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-17T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790d1212e650b89b26f99f1e231",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Requires assistance with email marketing campaigns.",
        },
      ],
      createdAt: "2025-01-18T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de650b89b26f99f1e2454235331",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Is seeking a mobile app developer for their project.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-19T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de642342350b89b26f99f1e231",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Needs a graphic designer to create a new logo.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-20T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de650b89b26f976459f1e231",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Requires help with video production for a marketing campaign.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-20T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
    {
      type: "DEFAULT",
      _id: "6790de650bfwecfwe89b26f99f1e231",
      conversationId: "6790de331ed45c2620102c5b",
      user: "660e9d968244121852218556",
      agent: getRandomizedAgentData(),
      fields: [
        {
          _id: "67938eca73abc6f7c423f017",
          key: "name",
          value: uniqueNamesGenerator({ dictionaries: [names] }),
        },
        {
          _id: "67938eca73abc6f7c423f018",
          key: "email",
          value: `${uniqueNamesGenerator({ dictionaries: [names] }).toLowerCase()}@example.com`,
        },
        {
          _id: "67938eca73abc6f7c423f01b",
          key: "user_request",
          value: "Wants to optimize their website for faster loading times.",
        },
        {
          _id: "67938eca73abc6f7c423f01d",
          key: "id",
          value: "6790de331ed45c2620102c5b",
        },
      ],
      createdAt: "2025-01-20T12:02:45.426Z",
      updatedAt: "2025-01-22T12:03:31.328Z",
      customAttributes: [],
    } as ILeadGen,
  ],
};
