import { Icons } from "@/components/ui/icons";

type Props = {
  iconKey: keyof typeof Icons;
  label: string;
};

export const StatsLeadsDetailsSidePanelLabel = ({ iconKey, label }: Props) => {
  const IconComponent = Icons[iconKey];

  return (
    <div className="flex items-center gap-2">
      <IconComponent className="size-4 text-neutral-750" />
      <p className="text-sm font-bold leading-[22px] text-neutral-750">{label}</p>
    </div>
  );
};
