import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { NotificationsIcon } from "@/components/Notifications/NotificationsIcon";
import { NotificationsActions } from "@/components/Notifications/NotificationsActions";
import { NotificationItem } from "@/components/Notifications/UngroupedNotification/NotificationItem";
import { useGetNotifications } from "@/data/queries/useGetNotifications";
import { useMemo } from "react";
import { groupAndSortNotifications } from "@/components/Notifications/utils/groupAndSortNotifications";
import { NotificationGroupedItem } from "@/components/Notifications/GroupedNotification/NotificationGroupedItem";

export const Notifications = () => {
  const { data: notifications } = useGetNotifications();

  const unreadNotifications = useMemo(() => notifications?.filter(notification => !notification.read), [notifications]);

  const groupedNotifications = useMemo(
    () => notifications && groupAndSortNotifications(notifications),
    [notifications]
  );

  if (!notifications) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <NotificationsIcon active={!!unreadNotifications?.length} />
      </PopoverTrigger>
      <PopoverContent
        className="mr-6 block h-[calc(100vh-55px)] w-[100vw] rounded-[20px] border border-gray-200 bg-white p-0 md:w-[60vw] lg:w-[50vw] xl:w-[40vw] 2xl:w-[30vw] 3xl:w-[27vw] 4xl:w-[25vw] 5xl:w-[23vw]"
        sideOffset={10}
      >
        <NotificationsActions unreadNotifications={unreadNotifications} />
        <div className="flex h-full flex-col overflow-y-scroll px-2.5">
          {groupedNotifications?.map(groupedNotification =>
            !groupedNotification.grouped || groupedNotification.notifications.length === 1 ? (
              <NotificationItem
                key={groupedNotification.notifications[0]._id}
                notification={groupedNotification.notifications[0]}
              />
            ) : (
              <NotificationGroupedItem
                groupedNotification={groupedNotification}
                key={groupedNotification.lastUpdateTime.toString()}
              />
            )
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
