import { cn } from "@/lib/utils";
import StatsDataTitle from "./common/StatsDataTitle";
import StatsTrendIndicator from "./common/StatsTrendIndicator";
import StatsDataTrendChart from "./common/StatsDataTrendChart";
import { StatsAnimatedValue } from "./common/StatsAnimatedValue/StatsAnimatedValue";
import { useEffect, useRef, useState } from "react";
import { StatsBlur } from "./common/StatsBlur";
import { LinkButton } from "@/components/ui/link-button";
import { Icons } from "@/components/ui/icons";
import { Link } from "react-router-dom";

type Props = {
  className?: string;
  title: string;
  value: number;
  trend?: number | null;
  icon?: React.ReactNode;
  blur?: boolean;
  animatedValueClassName?: string;
  hideTrendChart?: boolean;
  link?: {
    to: string;
    label: string;
  };
};

const StatsTopCard = ({
  className,
  title,
  value,
  trend,
  icon,
  blur,
  animatedValueClassName,
  hideTrendChart,
  link,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="w-full @container/card">
      <div
        ref={ref}
        className={cn(
          "relative h-full grow rounded-xl border border-neutral-50 bg-bg-light p-3 @sm/card:px-7 @sm/card:py-6",
          className
        )}
      >
        {blur && (
          <StatsBlur className="rounded-xl p-3 md:px-7 md:py-6">
            <StatsDataTitle title={title} />
          </StatsBlur>
        )}

        <div className="relative flex items-center justify-between gap-1 @sm/card:gap-6">
          <div>
            <StatsDataTitle
              title={title}
              className={cn({
                "opacity-0": blur,
              })}
            />
            <StatsAnimatedValue value={value} className={animatedValueClassName} />
          </div>

          {!!trend && !hideTrendChart && <StatsDataTrendChart trend={trend} parentWidth={width} maxWidth={90} />}
          {icon && <div className="rounded-[4px] bg-white p-1 shadow-sm sm:p-2.5 md:rounded-xl">{icon}</div>}
        </div>

        <div className="mt-1 flex justify-between sm:mt-3">
          <StatsTrendIndicator trend={trend} />

          {link && (
            <Link to={link.to}>
              <LinkButton size="md" className="text-sm text-primary-400">
                {link.label}
                <Icons.ChevronRight className="w-6" />
              </LinkButton>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatsTopCard;
