import type { OpenApiSchema, Operation } from "@/types/open-api";

export function addCodeSamples(openApiJson: OpenApiSchema, apiKey: string): OpenApiSchema {
  const modifiedSpec = JSON.parse(JSON.stringify(openApiJson)) as OpenApiSchema;

  for (const path in modifiedSpec.paths) {
    for (const method in modifiedSpec.paths[path]) {
      const operation = modifiedSpec.paths[path][method];
      try {
        const finalPath = replacePathParams(path, operation);

        const serverUrl = modifiedSpec.servers?.[0]?.url || "https://api.example.com";
        const url = `${serverUrl}${finalPath}`;

        const requestBody = getRequestBodyExample(operation);

        const codeSamples = [
          {
            lang: "shell (cURL)",
            source: generateCurlSample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "Python (requests)",
            source: generatePythonSample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "Node.js (Fetch)",
            source: generateNodeFetchSample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "Node.js (Axios)",
            source: generateNodeAxiosSample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "Go (native)",
            source: generateGoSample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "Ruby (Net::HTTP)",
            source: generateRubySample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "Java (OkHttp)",
            source: generateJavaSample(url, method.toUpperCase(), requestBody, apiKey),
          },
          {
            lang: "PHP (cURL)",
            source: generatePhpSample(url, method.toUpperCase(), requestBody, apiKey),
          },
        ];

        operation["x-code-samples"] = codeSamples.filter(sample => sample.source);
      } catch (error) {
        console.error(`Failed to generate code samples for ${method.toUpperCase()} ${path}:`, error);
      }
    }
  }

  return modifiedSpec;
}

function replacePathParams(originalPath: string, operation: Operation): string {
  let replacedPath = originalPath;

  if (operation.parameters) {
    for (const param of operation.parameters) {
      if (param.in === "path" && param.example) {
        const placeholder = `{${param.name}}`;
        replacedPath = replacedPath.replace(placeholder, String(param.example));
      }
    }
  }

  return replacedPath;
}

function getRequestBodyExample(operation: Operation): string | null {
  const content = operation.requestBody?.content?.["application/json"];
  if (content?.example) {
    return JSON.stringify(content.example, null, 2);
  }
  return null;
}

function generateCurlSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `curl -X ${method} "${url}" \\
  -H 'x-api-key: ${apiKey}' \\
  -H 'Content-Type: application/json' \\
  -d '${body}'`;
  }
  return `curl -X ${method} "${url}" \\
  -H 'x-api-key: ${apiKey}'`;
}

function generatePythonSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `import requests

response = requests.${method.toLowerCase()}("${url}",
  headers={"x-api-key": "${apiKey}", "Content-Type": "application/json"},
  json=${body}
)
print(response.json())`;
  }
  return `import requests

response = requests.${method.toLowerCase()}("${url}",
  headers={"x-api-key": "${apiKey}"}
)
print(response.json())`;
}

function generateNodeFetchSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `const fetch = require('node-fetch');

const response = await fetch('${url}', {
  method: '${method}',
  headers: {
    'x-api-key': '${apiKey}',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(${body})
});
const data = await response.json();
console.log(data);`;
  }
  return `const fetch = require('node-fetch');

const response = await fetch('${url}', {
  method: '${method}',
  headers: {
    'x-api-key': '${apiKey}'
  }
});
const data = await response.json();
console.log(data);`;
}

function generateNodeAxiosSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `const axios = require('axios');

const response = await axios.${method.toLowerCase()}('${url}', ${body}, {
  headers: {
    'x-api-key': '${apiKey}',
    'Content-Type': 'application/json'
  }
});
console.log(response.data);`;
  }
  return `const axios = require('axios');

const response = await axios.${method.toLowerCase()}('${url}', {
  headers: {
    'x-api-key': '${apiKey}'
  }
});
console.log(response.data);`;
}

function generateGoSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `package main

import (
  "fmt"
  "net/http"
  "bytes"
  "io/ioutil"
)

func main() {
  reqBody := []byte(${body})
  req, _ := http.NewRequest("${method}", "${url}", bytes.NewBuffer(reqBody))
  req.Header.Set("x-api-key", "${apiKey}")
  req.Header.Set("Content-Type", "application/json")

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer resp.Body.Close()

  bodyBytes, _ := ioutil.ReadAll(resp.Body)
  fmt.Println(string(bodyBytes))
}`;
  }
  return `package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {
  req, _ := http.NewRequest("${method}", "${url}", nil)
  req.Header.Set("x-api-key", "${apiKey}")

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer resp.Body.Close()

  bodyBytes, _ := ioutil.ReadAll(resp.Body)
  fmt.Println(string(bodyBytes))
}`;
}

function generateRubySample(url: string, method: string, body: string | null, apiKey: string): string {
  const methodCapitalized = method.charAt(0) + method.slice(1).toLowerCase();
  if (body) {
    return `require 'net/http'
require 'uri'
require 'json'

uri = URI.parse("${url}")
request = Net::HTTP::${methodCapitalized}.new(uri)
request["x-api-key"] = "${apiKey}"
request["Content-Type"] = "application/json"
request.body = ${body}

response = Net::HTTP.start(uri.hostname, uri.port) do |http|
  http.request(request)
end

puts response.body`;
  }
  return `require 'net/http'
require 'uri'

uri = URI.parse("${url}")
request = Net::HTTP::${methodCapitalized}.new(uri)
request["x-api-key"] = "${apiKey}"

response = Net::HTTP.start(uri.hostname, uri.port) do |http|
  http.request(request)
end

puts response.body`;
}

function generateJavaSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `OkHttpClient client = new OkHttpClient();
MediaType mediaType = MediaType.get("application/json");
RequestBody requestBody = RequestBody.create(${body}, mediaType);

Request request = new Request.Builder()
  .url("${url}")
  .method("${method}", requestBody)
  .addHeader("x-api-key", "${apiKey}")
  .addHeader("Content-Type", "application/json")
  .build();

Response response = client.newCall(request).execute();
System.out.println(response.body().string());`;
  }
  return `OkHttpClient client = new OkHttpClient();

Request request = new Request.Builder()
  .url("${url}")
  .method("${method}", null)
  .addHeader("x-api-key", "${apiKey}")
  .build();

Response response = client.newCall(request).execute();
System.out.println(response.body().string());`;
}

function generatePhpSample(url: string, method: string, body: string | null, apiKey: string): string {
  if (body) {
    return `$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, "${url}");
curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "${method}");
curl_setopt($ch, CURLOPT_POSTFIELDS, ${body});
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, array("x-api-key: ${apiKey}", "Content-Type: application/json"));
$response = curl_exec($ch);
curl_close($ch);
echo $response;`;
  }
  return `$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, "${url}");
curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "${method}");
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, array("x-api-key: ${apiKey}"));
$response = curl_exec($ch);
curl_close($ch);
echo $response;`;
}
