import type { OpenApiSchema } from "@/types/open-api";
import { baseURL } from "@/data/initAxios";

export const prefillApiUrl = (openApiJson: OpenApiSchema): OpenApiSchema => {
  const modifiedOpenApi = JSON.parse(JSON.stringify(openApiJson)) as OpenApiSchema;

  const truncatedUrl = baseURL.replace("/api/v1", "");

  modifiedOpenApi.servers = [
    {
      url: truncatedUrl,
    },
  ];

  return modifiedOpenApi;
};
