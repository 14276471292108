import { Slider } from "@/components/ui/slider";
import { MESSAGES_SLIDER_VALUES } from "../constants/chatHistory";
import { cn } from "@/lib/utils";

type Props = {
  className?: string;
  value?: number[] | undefined;
  onSliderValueChange: (values: number[]) => void;
};

const ChatHistoryContentMessagesRangeSlider = ({ className, value, onSliderValueChange }: Props) => {
  const getSliderPositionLeft = (index: number) => {
    const isFirst = index === 0;

    const numOfSections = MESSAGES_SLIDER_VALUES.length - 1;
    const offsetLeft = isFirst ? 0 : (index / numOfSections) * 100;
    const thumbWidth = 16;
    const isNumOfSectionsEven = numOfSections % 2 === 0;
    const thumbOffset = isNumOfSectionsEven ? thumbWidth / numOfSections : thumbWidth / numOfSections / 2;
    const isLeftHalf = index < numOfSections / 2;
    const left = `calc(${offsetLeft}% ${isLeftHalf ? "+" : "-"} ${thumbOffset}px)`;

    return left;
  };

  return (
    <div className={className}>
      <Slider
        value={value}
        minStepsBetweenThumbs={1}
        step={1}
        min={0}
        max={MESSAGES_SLIDER_VALUES.length - 1}
        onValueChange={onSliderValueChange}
      />
      <div className="relative mt-2">
        {MESSAGES_SLIDER_VALUES?.map((value, index) => {
          const isLast = index === MESSAGES_SLIDER_VALUES.length - 1;
          const left = getSliderPositionLeft(index);

          return (
            <span
              key={index}
              className={cn("absolute -translate-x-1/2", {
                "-translate-x-full": isLast,
              })}
              style={{
                left,
              }}
            >
              {value}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default ChatHistoryContentMessagesRangeSlider;
