import { Skeleton } from "@/components/ui/skeleton";

export const ChatHistoryDialogLoader = () => {
  return Array.from({ length: 5 })?.map((_, index) => (
    <div key={index} className="h-12 px-1">
      <div key={index} className="custom-skeleton-container flex justify-between lg:px-6">
        <Skeleton height={18} width={200} />
        <Skeleton height={18} width={100} />
      </div>
    </div>
  ));
};
