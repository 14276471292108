import { useState, useEffect } from "react";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Icons } from "@/components/ui/icons";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { toast } from "react-toastify";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { ExternalAgentStatus, type ExternalAgent, type WhatsAppAgent } from "@/types/agent";
import { usePauseExternalAgent } from "@/data/mutations/external-agents/usePauseExternalAgent";
import { useStartExternalAgent } from "@/data/mutations/external-agents/useStartExternalAgent";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { DeleteExternalAgentDialog } from "@/components/dialogs/DeleteExternalAgentDialog/DeleteExternalAgentDialog";
import { IntegrationStatus } from "../IntegrationStatus";
import { useCreateWhatsAppAgent } from "@/data/mutations/external-agents/useCreateWhatsAppAgent";
import { WhatsAppDialog } from "./WhatsAppDialog";
import { Button } from "@/components/ui/button";
import { ExternalAgentPlatform } from "@/types/origin";
import type { WaPayload } from "@/types/integrations/whatsapp";

declare let FB: {
  login: (callback: (response: LoginResponse) => void, appInfo: FbAppAccountInfo) => void;
};

interface LoginResponse {
  status: "connected" | "not_authorized" | "unknown";
  authResponse: AuthResponse | null;
}

interface AuthResponse {
  code: string;
  userId: string;
  expiresIn: number;
  signedRequest: string;
  reauthorize_required_in: number;
}

interface FbAppAccountInfo {
  config_id: string;
  response_type: string;
  override_default_response_type: boolean;
  extras: {
    feature: string;
    sessionInfoVersion: number;
  };
}

type FbEvent = {
  data: string;
  origin: string;
};

type FbEventData = {
  data: { current_step: string; phone_number_id: string; waba_id: string; error_message: string };
  type: string;
  event: string;
};

export const WhatsAppTile = ({ externalAgents }: { externalAgents: ExternalAgent[] | undefined }) => {
  const whatsAppAgent = externalAgents?.find(
    (agent): agent is WhatsAppAgent => agent.platform === ExternalAgentPlatform.WHATSAPP
  );

  const [isDialogOpen, setShowDialog] = useState(false);

  const { getValues: getAgentFormValues } = useAgentFormContext();
  const agentId = getAgentFormValues("_id");
  // mutations
  const { mutate: pauseExternalAgent, isPending: isPausing } = usePauseExternalAgent();
  const { mutate: startExternalAgent, isPending: isStarting } = useStartExternalAgent();
  const { mutate: createWhatsAppAgent, isPending: isWhatsAppAgentCreating } = useCreateWhatsAppAgent();

  const isActive = !!whatsAppAgent?.active && whatsAppAgent?.status === ExternalAgentStatus.ONLINE;
  const isConfigured =
    whatsAppAgent?.status === ExternalAgentStatus.ONLINE || whatsAppAgent?.status === ExternalAgentStatus.PAUSED;

  const [whatsAppToken, setWhatsAppToken] = useState("");
  const [phoneNumberId, setPhoneNumberId] = useState(whatsAppAgent?.platformAttributes.phoneNumberId);
  const [wabaId, setWabaId] = useState(whatsAppAgent?.platformAttributes.wabaId);

  const handleCheckedChange = (checked: boolean) => {
    if (!whatsAppAgent) {
      if (checked) {
        launchWhatsAppSignup();
      }
      return;
    }
    const externalAgentId = whatsAppAgent._id;
    if (checked) {
      startExternalAgent({ externalAgentId, agentId }); // resume
    } else {
      pauseExternalAgent({ externalAgentId, agentId }); // pause
    }
  };

  const sessionInfoListener = (event: FbEvent) => {
    if (event.origin == null) {
      return;
    }

    // Make sure the data is coming from facebook.com
    if (!event.origin.includes("facebook.com")) {
      return;
    }

    try {
      const data: FbEventData = JSON.parse(event?.data) as FbEventData;
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        // if user finishes the Embedded Signup flow
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
          setPhoneNumberId(phone_number_id); // Phone number ID
          setWabaId(waba_id); // WhatsApp business account ID
          return;
        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === "ERROR") {
          toast.error("Oops, onboard WhatsApp failed. Please try again.");
          return;
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          toast.warn(`WhatsApp onboarding process cancelled at ${current_step}`);
          return;
        }
      }
    } catch (error) {
      // Don’t parse info that’s not a JSON
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("message", sessionInfoListener);
    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  });

  useEffect(() => {
    if (agentId && whatsAppToken && phoneNumberId && wabaId && !whatsAppAgent) {
      const payload = {
        agentId,
        token: whatsAppToken,
        phoneNumberId,
        wabaId,
      } as WaPayload;
      createWhatsAppAgent(payload, { onError: error => console.log("error: ", error) });
    }
  }, [createWhatsAppAgent, agentId, whatsAppToken, phoneNumberId, wabaId, whatsAppAgent]);

  // Facebook Login with JavaScript SDK
  function launchWhatsAppSignup() {
    // Launch Facebook login
    FB.login(
      function (response) {
        if (response.authResponse) {
          const { code } = response.authResponse; // there are also userId, expiresIn in authResponse
          setWhatsAppToken(code);
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          toast.warn(`WhatsApp onboarding process cancelled.`);
        }
      },
      {
        config_id: import.meta.env.VITE_META_FB_SIGNUP_CONFIG_ID as string, // configuration ID obtained from Facebook Login for Business
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          feature: "whatsapp_embedded_signup",
          sessionInfoVersion: 2, //  Receive Session Logging Info
        },
      }
    );
  }

  return (
    <>
      <div
        className={cn(
          "relative flex max-w-[30.25rem] flex-col justify-between gap-4 overflow-hidden rounded-md p-4",
          whatsAppAgent ? "border border-neutral-300" : "shadow-integration"
        )}
      >
        <div className="flex gap-4">
          {isWhatsAppAgentCreating ? (
            <LoadingSpinner className="flex size-16 items-center justify-center" />
          ) : (
            <Icons.WhatsUp className="size-16 text-[#25D366]" />
          )}
          <div className="flex w-full flex-col gap-4">
            <div className="flex items-center justify-between gap-4">
              <div className="flex gap-4">
                <span className="text-base font-bold">WhatsApp</span>
                <IntegrationStatus status={whatsAppAgent?.status} />
              </div>
              <Switch disabled={isPausing || isStarting} checked={isActive} onCheckedChange={handleCheckedChange} />
            </div>
            <p className="text-xs font-medium leading-relaxed">Deploy the agent to a WhatsApp Business profile.</p>
          </div>
        </div>
        <div className="flex justify-between gap-2">
          {isConfigured ? (
            <span
              className="flex cursor-pointer items-center gap-1 px-4 text-xs font-medium text-neutral-400 hover:underline"
              onClick={() => setShowDialog(true)}
            >
              <Icons.CheckCircle className="text-success" />
              {whatsAppAgent?.platformAttributes.wabaName}{" "}
              {whatsAppAgent?.platformAttributes.phoneNumber.length >= 4 &&
                `(***${whatsAppAgent?.platformAttributes.phoneNumber.slice(-4)})`}
              <HybridTooltipPopover>Click to edit WhatsApp message templates.</HybridTooltipPopover>
            </span>
          ) : (
            <Button variant="tertiary" roundedType="rounded" size="tiny" onClick={launchWhatsAppSignup}>
              Configure
            </Button>
          )}
          {whatsAppAgent && (
            <DeleteExternalAgentDialog externalAgentId={whatsAppAgent._id} platform={ExternalAgentPlatform.WHATSAPP} />
          )}
        </div>
      </div>
      <WhatsAppDialog agentData={whatsAppAgent} isDialogOpen={isDialogOpen} setShowDialog={setShowDialog} />
    </>
  );
};
