import { Avatar } from "@/components/Avatar";
import { GeneratedImage } from "@/components/ui/GeneratedImage";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { useGetUser } from "@/data/queries/useGetUser";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { format } from "date-fns";
import { memo, type LegacyRef } from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";

import { ActionLoader } from "./ActionLoader";
import { ContactForm } from "./ContactForm";
import { MessageLoader } from "./MessageLoader";
import { References } from "./References";
import { VoteMessage } from "./VoteMessage";
import { VoiceMessage } from "./VoiceMessage";

import type { UploadChatFileResponse } from "@/data/mutations/useUploadFileToConversation";
import type { Message } from "@/types/conversation";
import type { Trace } from "@/types/trace";
import type { EmbedKnowledges } from "@/types/agent";

export const MESSAGE_HIGHLIGHT_CLASS = "message-highlight-container";

export type MessageCloudProps = {
  message: Message["text"];
  isBotMessage: boolean;
  vote: Message["vote"];
  messageId?: Message["_id"];
  messageIndex?: number;
  conversationId?: Message["conversationId"];
  botNames: string[];
  createdAt?: Message["createdAt"];
  trace?: Trace | null;
  isBookmark: Message["isBookmark"];
  agent?: {
    _id: string;
    name: string;
    avatar: string;
    creator?: string;
  };
  isGeneratingBotResponse?: boolean;
  attachments?: UploadChatFileResponse[];
  messageUser?: Message["user"];
  disableActions?: boolean;
  audio?: Message["audio"];
  className?: string;
  displayUserDetails?: {
    name: string;
    avatar: string;
  };
  cot?: string;
  setDocumentInPreview: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>> | undefined;
  setDocPreviewPage: React.Dispatch<React.SetStateAction<number | null>> | undefined;
};

const _MessageCloud = ({
  message,
  messageId,
  isBotMessage,
  trace,
  vote,
  conversationId,
  createdAt,
  messageIndex,
  isBookmark,
  agent,
  botNames,
  isGeneratingBotResponse,
  cot,
  attachments,
  messageUser,
  disableActions,
  audio,
  displayUserDetails,
  setDocumentInPreview,
  setDocPreviewPage,
}: MessageCloudProps) => {
  const { user } = useGetUser();
  const [, copy] = useCopyNotify();

  const handleCopyTableContent = (tableId: string) => {
    const table = document.getElementById(tableId);
    if (table) {
      copy(table.innerText); // Copy table's text content
    }
  };

  if (!isBotMessage) {
    if (audio?.base64 || audio?.blob || audio?.url) {
      return (
        <div className="flex flex-col gap-1 p-4 hover:bg-primary-50" id={messageId}>
          <div className="flex items-center gap-3">
            <Avatar name={user?.name} src={user?.avatar} className="border border-neutral-200" />
            <div className="flex items-center gap-2">
              <div className="text-base font-bold text-primary-black">Me</div>
              {createdAt && <p className="pt-[3px] text-xs text-neutral-500">{format(createdAt, "HH:mm")}</p>}
            </div>
          </div>
          <div className="pl-[54px]">
            <VoiceMessage messageId={messageId ?? ""} conversationId={conversationId ?? ""} audio={audio} />
          </div>
        </div>
      );
    }
    const botTags = botNames.map(name => `@${name}`);

    const getMessageWithTags = ({ message, botTags }: { message: string; botTags: string[] }) => {
      const parts = message.split(new RegExp(`(${botTags.join("|")})`, "gi"));
      return parts.map((part, index) => {
        if (botTags.includes(part)) {
          return (
            <span key={index} className="bg-focus-bg px-0.5 py-1 font-semibold text-focus">
              {part}
            </span>
          );
        } else {
          return part;
        }
      });
    };

    const isUserMe = user?._id === messageUser;

    const getUserAvatar = () => {
      if (displayUserDetails) {
        return <Avatar name={displayUserDetails.name} src={displayUserDetails.avatar} />;
      }
      return <Avatar name={isUserMe ? user?.name : "User"} src={isUserMe ? user?.avatar : undefined} />;
    };

    const getUserName = () => {
      if (displayUserDetails) {
        return displayUserDetails.name;
      }
      return isUserMe ? "Me" : "User";
    };

    return (
      <div className="flex flex-col gap-1 p-4 hover:bg-primary-50" id={messageId}>
        <div className="flex items-center gap-3">
          {getUserAvatar()}
          <div className="flex items-center gap-2">
            <div className="text-base font-bold text-primary-black">{getUserName()}</div>
            {createdAt && <p className="pt-[3px] text-xs text-neutral-500">{format(createdAt, "HH:mm")}</p>}
          </div>
        </div>
        <div
          className={cn(
            "whitespace-pre-wrap break-words pl-[54px] text-base font-medium text-primary-black",
            MESSAGE_HIGHLIGHT_CLASS
          )}
        >
          {getMessageWithTags({ message, botTags })}
        </div>
        {!!attachments && (
          <div className="mt-3 flex w-full items-center gap-4 overflow-x-auto pl-[54px]">
            {attachments.map(attachment => {
              const isImageFileType = attachment.mimetype.split("/")[0] === "image";
              const isPdfFileType = attachment.mimetype === "application/pdf";
              if (isImageFileType) {
                return (
                  <div
                    title={attachment.originalname}
                    key={attachment.filename}
                    style={{ backgroundImage: `url('${attachment?.url}')` }}
                    className="group relative size-16 min-w-16 rounded-md border border-neutral-300 bg-neutral-300 bg-cover bg-center"
                  ></div>
                );
              }
              return (
                <div
                  title={attachment.originalname}
                  key={attachment.filename}
                  className="group relative flex gap-2 rounded-md border border-neutral-300 p-3"
                >
                  <div className="flex size-10 min-w-10 items-center justify-center rounded-[4px] bg-primary-400">
                    {isPdfFileType ? (
                      <Icons.Pdf className="size-6 text-white [&>*]:!fill-primary-400" />
                    ) : (
                      <Icons.File className="size-6 text-white" />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <span className="max-w-52 truncate text-base font-bold">{attachment.originalname}</span>
                    <span className="text-xs font-medium uppercase text-neutral-400">
                      {attachment.mimetype.split("/")[1]}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  if (isGeneratingBotResponse && agent) {
    return <MessageLoader agent={agent} withDelay />;
  }

  const showContactFormIntents =
    trace?.meta?.intent === "want real person or phone call support" ||
    trace?.meta?.intent === "want to make a purchase";

  const showContactForm =
    showContactFormIntents && !!conversationId && typeof messageIndex === "number" && messageIndex < 4;

  const filteredReferences = trace?.reference?.map(item => ({
    ...item,
    ["source"]: "",
  }));

  const traceFunction = trace?.meta?.function || null;

  const useFilteredReferences = !(agent?.creator && user && user._id === agent.creator);

  return (
    <div className="flex flex-col gap-1 p-4 hover:bg-primary-50" id={messageId}>
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <Avatar name={agent?.name} src={agent?.avatar} />
          <div className="flex items-center gap-2">
            <div className="text-sm font-bold text-primary-black">{agent?.name}</div>
            {createdAt && <p className="pt-[3px] text-xs text-neutral-500">{format(createdAt, "HH:mm")}</p>}
          </div>
        </div>
        {isBookmark && (
          <div className="ml-2 flex items-center gap-1">
            <Icons.BookmarkFilled className="h-3 text-primary-400" />
            <p className=" text-[0.625rem] font-medium text-neutral-500">Added to bookmark</p>
          </div>
        )}
      </div>
      {cot && (
        <div className="ml-[54px] flex flex-col gap-2 whitespace-pre-wrap break-words text-base font-medium text-primary-black">
          <div className="flex w-fit gap-1 rounded-lg bg-primary-300 px-2 py-1 text-xs text-white">
            <Icons.Universe className={`h-4 ${!message && "animate-spin"} text-white`} />
            {!message ? "Thinking: " : "Thought: "}
          </div>
          <div className="border-l-2 border-primary-200 pl-1 text-left text-base text-xs font-medium italic text-gray-600 text-primary-black">
            {cot}
          </div>
        </div>
      )}
      <div className="ml-[54px] whitespace-pre-wrap break-words text-base font-medium text-primary-black">
        <Markdown
          remarkPlugins={[remarkGfm]}
          children={message}
          components={{
            a: ({ node, ...props }) => (
              <a
                {...props}
                className={cn("text-sky-900 underline decoration-sky-900 hover:text-sky-600", MESSAGE_HIGHLIGHT_CLASS)}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            ol: ({ node, ...props }) => (
              <ol
                {...props}
                className={cn("ml-5 list-inside list-decimal whitespace-normal", MESSAGE_HIGHLIGHT_CLASS)}
              />
            ),
            ul: ({ node, ...props }) => (
              <ul {...props} className={cn("ml-5 list-inside list-disc whitespace-normal", MESSAGE_HIGHLIGHT_CLASS)} />
            ),
            li: ({ node, ...props }) => <li {...props} className={cn("my-2 list-item", MESSAGE_HIGHLIGHT_CLASS)} />,
            h2: ({ node, ...props }) => <h2 {...props} className={cn("text-lg", MESSAGE_HIGHLIGHT_CLASS)} />,
            table: ({ node, children, ...props }) => {
              const tableId = `agx-table-${Math.random().toString(36).substring(2, 9)}`;
              return (
                <div className="group relative">
                  <div
                    className="absolute right-0 top-0 hidden cursor-pointer items-center gap-1 p-1 group-hover:flex"
                    onClick={() => {
                      handleCopyTableContent(tableId);
                    }}
                  >
                    <Icons.CopyChat className="w-5" />
                  </div>
                  <table
                    {...props}
                    className={cn("min-w-full divide-y divide-gray-200", MESSAGE_HIGHLIGHT_CLASS)}
                    id={tableId}
                  >
                    {children}
                  </table>
                </div>
              );
            },
            thead: ({ node, ...props }) => <thead {...props} className={cn("bg-gray-50", MESSAGE_HIGHLIGHT_CLASS)} />,
            tbody: ({ node, ...props }) => (
              <tbody {...props} className={cn("divide-y divide-gray-200 bg-white", MESSAGE_HIGHLIGHT_CLASS)} />
            ),
            tr: ({ node, ...props }) => <tr {...props} className={MESSAGE_HIGHLIGHT_CLASS} />,
            th: ({ node, ...props }) => (
              <th
                {...props}
                scope="col"
                className={cn(
                  "border border-gray-200 px-4 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500",
                  MESSAGE_HIGHLIGHT_CLASS
                )}
              />
            ),
            td: ({ node, ...props }) => (
              <td {...props} className={cn("whitespace-nowrap border px-4 py-2", MESSAGE_HIGHLIGHT_CLASS)} />
            ),
            pre: ({ node, ...props }) => <pre {...props} className={cn("overflow-x-auto", MESSAGE_HIGHLIGHT_CLASS)} />,
            code(props) {
              const { children, className, node, style, ...rest } = props;
              const match = /language-(\w+)/.exec(className || "");
              const ref = props.ref as LegacyRef<SyntaxHighlighter> | undefined;
              return match ? (
                <div className="rounded-md border border-neutral-300 bg-[#F5F2F0] tracking-wide">
                  <div className="mb-[-7px] flex h-[24px] w-full items-center justify-between rounded-t-md border-b border-neutral-300 bg-[#e7e0db] px-2 text-sm text-neutral-500">
                    <div>{match[1]}</div>
                    <div
                      className="flex cursor-pointer items-center gap-1"
                      onClick={() => copy(String(children).replace(/\n$/, ""))}
                    >
                      copy
                      <Icons.CopyChat className="w-4" />
                    </div>
                  </div>
                  <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    ref={ref}
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                  />
                </div>
              ) : (
                <code {...rest} className={className + "bg-[#F5F2F0]" + MESSAGE_HIGHLIGHT_CLASS}>
                  {children}
                </code>
              );
            },
            p: ({ node, ...props }) => (
              <p
                {...props}
                className={cn("text-left text-base font-medium text-primary-black", MESSAGE_HIGHLIGHT_CLASS)}
              />
            ),
            img: ({ node, ...props }) => (
              <img {...props} className=" mb-4 w-full max-w-[450px] rounded-md object-contain" />
            ),
          }}
        />
        {trace?.genImage?.status && <GeneratedImage genImage={trace.genImage} />}
      </div>

      {trace?.actionType && trace.genImage?.status !== "success" && (
        <ActionLoader action={trace.actionType} actionObject={trace.otherActionObject} />
      )}
      {!trace?.actionType && messageId && !disableActions && (
        <VoteMessage
          messageId={messageId}
          conversationId={conversationId}
          agentId={agent?._id}
          vote={vote}
          message={trace?.genImage?.prompt || message}
          isBookmark={isBookmark}
        />
      )}
      {(trace?.reference || traceFunction) && !showContactForm && !useFilteredReferences && (
        <References
          references={trace?.reference || []}
          agentFunction={traceFunction}
          setDocumentInPreview={setDocumentInPreview}
          setDocPreviewPage={setDocPreviewPage}
        />
      )}
      {filteredReferences && !showContactForm && useFilteredReferences && (
        <References
          references={filteredReferences}
          agentFunction={traceFunction}
          setDocumentInPreview={setDocumentInPreview}
          setDocPreviewPage={setDocPreviewPage}
        />
      )}

      {showContactForm && <ContactForm conversationId={conversationId} />}
    </div>
  );
};

export const MessageCloud = memo(_MessageCloud);
