import { useGetAgents } from "@/data/queries/useGetAgents";
import { useEffect, useState } from "react";
import { StatsMainChartHeader } from "./StatsMainChartHeader";
import { StatsAllAgentsPerformance } from "./StatsAllAgentsPerformance/StatsAllAgentsPerformance";
import { StatsSingleAgentPerformance } from "./StatsSingleAgentPerformance/StatsSingleAgentPerformance";
import { StatsIntervals } from "@/types/stats";
import type { StatsSingleAgentPerformanceDataType, StatsInterval, StatsMainChartSectionTab } from "@/types/stats";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { ALL_AGENTS_STATS_LEGEND } from "../constants/statsConstants";
import StatsAllDataIcon from "./common/StatsAllDataIcon";
import { StatsIntervalPicker } from "./StatsIntervalPicker";
import StatsDataTypeIcon from "./common/StatsDataTypeIcon";
import StatsSingleAgentPerformanceDataTypePicker from "./StatsSingleAgentPerformance/StatsSingleAgentPerformanceDataTypePicker";
import { StatsAgentsPicker } from "./StatsAgentsPicker/StatsAgentsPicker";

type Props = {
  activeSectionTab: StatsMainChartSectionTab;
  onActiveSectionChange: (section: StatsMainChartSectionTab) => void;
};

export const StatsAgentsPerformace = ({ activeSectionTab, onActiveSectionChange }: Props) => {
  const { selectedAgents, setSelectedAgents, isUpgradeNeeded } = useStatsContext();
  const [selectedDataType, setSelectedDataType] = useState<StatsSingleAgentPerformanceDataType | null>(null);
  const [interval, setInterval] = useState<StatsInterval>(StatsIntervals.DAILY);

  const { data: allAgents } = useGetAgents({
    enabled: !isUpgradeNeeded,
  });

  useEffect(() => {
    if (allAgents) {
      setSelectedAgents(allAgents);
    }
  }, [allAgents, setSelectedAgents]);

  const isSingleAgentSelected = selectedAgents.length === 1;

  const getDataTypeDropdownIcon = () => {
    if (!selectedDataType) {
      return <StatsAllDataIcon />;
    }

    return <StatsDataTypeIcon selectedDataType={selectedDataType} />;
  };

  const getDataTypeDropdownTitle = () => {
    if (!selectedDataType) {
      return "All data";
    }

    return ALL_AGENTS_STATS_LEGEND[selectedDataType].label;
  };

  return (
    <>
      <StatsMainChartHeader activeSectionTab={activeSectionTab} onActiveSectionChange={onActiveSectionChange}>
        <div className="flex items-center gap-2">
          {isSingleAgentSelected && (
            <StatsSingleAgentPerformanceDataTypePicker
              onChangeSelectedDataType={setSelectedDataType}
              triggerIcon={getDataTypeDropdownIcon()}
              triggerTitle={getDataTypeDropdownTitle()}
            />
          )}

          <StatsAgentsPicker
            selectedAgents={selectedAgents}
            setSelectedAgents={setSelectedAgents}
            allAgents={allAgents}
            disabled={isUpgradeNeeded}
          />

          {isSingleAgentSelected && (
            <StatsIntervalPicker statsInterval={interval} onStatsIntervalChange={setInterval} />
          )}
        </div>
      </StatsMainChartHeader>

      {isSingleAgentSelected ? (
        <StatsSingleAgentPerformance
          agentId={selectedAgents[0]._id}
          interval={interval}
          selectedDataType={selectedDataType}
        />
      ) : (
        <StatsAllAgentsPerformance />
      )}
    </>
  );
};
