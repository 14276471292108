import { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";

export const MAX_DURATION_IN_SECONDS = 60;
const MIN_DURATION_IN_SECONDS = 1.5;

const errorHandler = (error: unknown) => {
  if (error instanceof DOMException) {
    switch (error.name) {
      case "NotAllowedError":
        toast.error("Permission denied: Please allow access to the microphone.");
        break;
      case "NotFoundError":
        toast.error("Microphone not found: Please connect a microphone.");
        break;
      case "NotReadableError":
        toast.error("Microphone is not accessible due to a hardware error.");
        break;
      case "OverconstrainedError":
        toast.error("Constraints cannot be satisfied by available devices.");
        break;
      case "SecurityError":
        toast.error("Permission denied due to security reasons.");
        break;
      case "TypeError":
        toast.error("No constraints provided or invalid constraints.");
        break;
      default:
        toast.error(`An unknown error occurred: ${error.message}`);
        break;
    }
  } else if (error instanceof Error) {
    toast.error(`Unexpected error occurred: ${error.message || "Error message not provided"}`);
  } else {
    toast.error("An unknown error occurred. Please try again later");
  }
};

export type AudioData = {
  blob: Blob;
  duration: number; // in milliseconds
} | null;

export type UseAudioRecorder = {
  isRecording: boolean;
  recordingTime: number;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<AudioData>;
  cancelRecording: () => void;

  isPlaying: boolean;
  hasAudio: boolean;
  currentTime: number; // playback current time in seconds
  duration: number; // total duration in seconds
  play: () => void;
  pause: () => void;
  deleteRecording: () => void;

  recordedAudio: AudioData;
};

type UseAudioRecorderProps = {
  autoSubmitSendMessage?: (messageText: string, audio: Exclude<AudioData, null>) => Promise<void>;
};

export default function useAudioRecorder({ autoSubmitSendMessage }: UseAudioRecorderProps): UseAudioRecorder {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasAudio, setHasAudio] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [recordedAudio, setRecordedAudio] = useState<AudioData>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const resolveStopRef = useRef<((value: AudioData) => void) | null>(null);
  const cancelRef = useRef(false);
  const startTimeRef = useRef<number | null>(null);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);

  const stopRecording = useCallback((): Promise<AudioData> => {
    return new Promise(resolve => {
      if (!mediaRecorderRef.current) {
        resolve(null);
        return;
      }

      resolveStopRef.current = resolve;
      mediaRecorderRef.current.stop();
    });
  }, []);

  const setupMediaRecorder = useCallback(async () => {
    try {
      if (!navigator.mediaDevices?.getUserMedia) {
        throw new Error("Media devices not supported");
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = MediaRecorder.isTypeSupported("audio/webm; codecs=opus")
        ? { mimeType: "audio/webm; codecs=opus" }
        : { mimeType: "audio/mp4; codecs=mp4a.40.2" };

      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstart = () => {
        setRecordingTime(0);
        setIsRecording(true);
        startTimeRef.current = performance.now();

        setIsPlaying(false);
        setHasAudio(false);
        setCurrentTime(0);
        setDuration(0);
        setRecordedAudio(null);
        audioElementRef.current = null;

        recordingIntervalRef.current = setInterval(() => {
          setRecordingTime(prev => {
            setDuration(prev + 1);
            setCurrentTime(prev + 1);
            const newTime = prev + 1;
            if (newTime > MAX_DURATION_IN_SECONDS && mediaRecorderRef.current?.state === "recording") {
              void stopRecording();
              toast.success(`Voice message reached ${MAX_DURATION_IN_SECONDS} seconds and was automatically stopped.`);
            }
            return newTime;
          });
        }, 1000);
      };

      mediaRecorder.onstop = () => {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        }
        if (recordingIntervalRef.current) {
          clearInterval(recordingIntervalRef.current);
          recordingIntervalRef.current = null;
        }
        setIsRecording(false);
        setRecordingTime(0);

        const recordingDurationInMs = performance.now() - (startTimeRef.current || 0);
        const recordingDurationInSeconds = recordingDurationInMs / 1000;

        if (recordingDurationInSeconds < MIN_DURATION_IN_SECONDS && !cancelRef.current) {
          toast.warning(`You can send recordings that are at least ${MIN_DURATION_IN_SECONDS}s long.`);
          cancelRef.current = true;
        }

        let audioData: AudioData = null;
        if (!cancelRef.current) {
          audioData = {
            blob: new Blob(audioChunksRef.current, { type: mediaRecorder.mimeType }),
            duration: recordingDurationInMs,
          };
        }

        audioChunksRef.current = [];
        cancelRef.current = false;

        if (resolveStopRef.current) {
          resolveStopRef.current(audioData);
          resolveStopRef.current = null;
        }

        if (audioData) {
          if (autoSubmitSendMessage) {
            void autoSubmitSendMessage("", audioData);
          }

          setRecordedAudio(audioData);
          const url = URL.createObjectURL(audioData.blob);

          if (!audioElementRef.current || audioElementRef.current.src !== url) {
            audioElementRef.current = new Audio(url);
            audioElementRef.current.onloadedmetadata = () => {
              setHasAudio(true);
            };

            audioElementRef.current.onended = () => {
              setIsPlaying(false);
              setCurrentTime(0);
            };
          }
        }
      };

      return true;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  }, [stopRecording, autoSubmitSendMessage]);

  const startRecording = useCallback(async () => {
    const isSuccess = await setupMediaRecorder();
    if (!isSuccess) {
      return;
    }

    mediaRecorderRef.current?.start();
  }, [setupMediaRecorder]);

  const cancelRecording = useCallback(() => {
    if (mediaRecorderRef.current?.state === "recording") {
      cancelRef.current = true;
      mediaRecorderRef.current.stop();
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isPlaying && audioElementRef.current) {
      timer = setInterval(() => {
        if (audioElementRef.current) {
          setCurrentTime(audioElementRef.current.currentTime);
          if (audioElementRef.current.ended) {
            setIsPlaying(false);
            setCurrentTime(0);
          }
        }
      }, 200);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isPlaying]);

  const play = useCallback(() => {
    if (!hasAudio || !audioElementRef.current) {
      return;
    }
    audioElementRef.current
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch(() => {
        toast.error("Could not play audio.");
      });
  }, [hasAudio]);

  const pause = useCallback(() => {
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      setIsPlaying(false);
    }
  }, []);

  const deleteRecording = useCallback(() => {
    if (isRecording) {
      return;
    }
    if (audioElementRef.current?.src) {
      URL.revokeObjectURL(audioElementRef.current.src);
    }
    audioElementRef.current = null;
    setIsPlaying(false);
    setHasAudio(false);
    setCurrentTime(0);
    setDuration(0);
    setRecordedAudio(null);
  }, [isRecording]);

  useEffect(() => {
    return () => {
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
      }
      cancelRecording();
    };
  }, [cancelRecording]);

  return {
    isRecording,
    recordingTime,
    startRecording,
    stopRecording,
    cancelRecording,
    isPlaying,
    hasAudio,
    currentTime,
    duration,
    play,
    pause,
    deleteRecording,
    recordedAudio,
  };
}
