import { Avatar } from "@/components/Avatar";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { sleep } from "@/utils/sleep";
import { useState } from "react";
import { FILE_INPUT_AGENT_ID, MAX_IMAGE_SIZE } from "@/constants/images";
import { isImageFormatAllowed } from "@/utils/fileUploads";
import { toast } from "react-toastify";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Controller } from "react-hook-form";
import { CropPhotoDialog } from "../../../../dialogs/CropPhotoDialog/CropPhotoDialog";
import { Button } from "@/components/ui/button";
import { AgentVoice } from "./AgentVoice/AgentVoice";

export const AgentAvatar = () => {
  const form = useAgentFormContext();
  const { control } = form;

  const [loadingGenerateAvatar, setLoadingGenerateAvatar] = useState(false);
  const [loadingUploadAvatar, setLoadingUploadAvatar] = useState(false);

  const [image, setImage] = useState<{
    src: string;
    name: string;
  }>({ src: "", name: "" });

  const uploadImage = async (file: File) => {
    if (!file) {
      return;
    }
    if (!isImageFormatAllowed(file)) {
      return toast.error("Image format not allowed");
    }
    if (file.size > MAX_IMAGE_SIZE) {
      return toast.error("Image size too large");
    }
    setLoadingUploadAvatar(true);
    const formData = new FormData();
    formData.append("avatar", file);
    try {
      const res = (await restClient.put<{ path: string }>(apiPaths.updateAgentAvatar, formData)).data;
      return res.path;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUploadAvatar(false);
    }
  };

  const openImagePicker = () => {
    const input = document.getElementById(FILE_INPUT_AGENT_ID);
    if (input) {
      input.click();
    }
  };

  const generateRandomAvatar = async () => {
    setLoadingGenerateAvatar(true);
    try {
      const { data } = await restClient.get<{ path: string }>(apiPaths.getRandomAvatar);
      await sleep(500);
      return data.path;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingGenerateAvatar(false);
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name || "";
      const reader = new FileReader();
      reader.addEventListener("load", () => setImage({ src: reader.result?.toString() || "", name: fileName }));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // TODO: remove when voice is ready for prod
  const isProd = !!import.meta.env.VITE_ENV && import.meta.env.VITE_ENV === "prod";

  return (
    <div className="flex flex-col items-center justify-center gap-4 sm:flex-row md:flex-col md:justify-start ">
      <Controller
        control={control}
        name="avatar"
        render={({ field: { value, onChange } }) => (
          <>
            <Avatar src={value} size="custom" className="size-20 sm:size-[160px]" />
            <input
              type="file"
              accept="image/*"
              hidden
              name="avatar"
              onChange={onSelectFile}
              id={FILE_INPUT_AGENT_ID}
              className="hidden"
            />
            <div className="flex flex-wrap items-center justify-center gap-4 sm:flex-col">
              <Button onClick={openImagePicker} variant="secondary" loading={loadingUploadAvatar}>
                Upload Avatar
              </Button>
              <Button
                onClick={async () => {
                  const path = await generateRandomAvatar();
                  onChange(path);
                }}
                loading={loadingGenerateAvatar}
                variant="secondary"
              >
                Generate Avatar
              </Button>
              {!isProd && <AgentVoice />}
            </div>
            <CropPhotoDialog
              onOpenChange={isOpen => {
                if (!isOpen) {
                  setImage({ src: "", name: "" });
                }
              }}
              image={image}
              onImageSave={async file => {
                const path = await uploadImage(file);
                onChange(path);
              }}
            />
          </>
        )}
      />
    </div>
  );
};
