import { Skeleton } from "@/components/ui/skeleton";

export const StatsLeadsTableSkeleton = () => {
  return (
    <div className="px-6">
      <div className="custom-skeleton-container mt-4 flex justify-between">
        <div className="ml-4 flex flex-col gap-3">
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
          <div className="custom-skeleton-container flex gap-2.5">
            <Skeleton height={20} width={20} className="" />
          </div>
        </div>

        <div className="flex flex-col gap-[3px]">
          <Skeleton height={15} width={110} className="" />
          <Skeleton height={15} width={90} className="mt-3" />
          <Skeleton height={15} width={90} className="mt-3.5" />
          <Skeleton height={15} width={90} className="mt-3.5" />
          <Skeleton height={15} width={90} className="mt-3.5" />
          <Skeleton height={15} width={90} className="mt-3.5" />
          <Skeleton height={15} width={90} className="mt-3.5" />
        </div>

        <div className="flex flex-col gap-[3px]">
          <Skeleton height={15} width={100} className="" />
          <Skeleton height={15} width={50} className="mt-3" />
          <Skeleton height={15} width={100} className="mt-3.5" />
          <Skeleton height={15} width={70} className="mt-3.5" />
          <Skeleton height={15} width={50} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
        </div>

        <div className="hidden flex-col gap-[3px] sm:flex">
          <Skeleton height={15} width={100} className="" />
          <Skeleton height={15} width={50} className="mt-3" />
          <Skeleton height={15} width={100} className="mt-3.5" />
          <Skeleton height={15} width={70} className="mt-3.5" />
          <Skeleton height={15} width={50} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
        </div>

        <div className="hidden flex-col gap-[3px] sm:flex">
          <Skeleton height={15} width={100} className="" />
          <Skeleton height={15} width={50} className="mt-3" />
          <Skeleton height={15} width={100} className="mt-3.5" />
          <Skeleton height={15} width={70} className="mt-3.5" />
          <Skeleton height={15} width={50} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
        </div>

        <div className="hidden flex-col gap-[3px] sm:flex">
          <Skeleton height={15} width={100} className="" />
          <Skeleton height={15} width={50} className="mt-3" />
          <Skeleton height={15} width={100} className="mt-3.5" />
          <Skeleton height={15} width={70} className="mt-3.5" />
          <Skeleton height={15} width={50} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
          <Skeleton height={15} width={80} className="mt-3.5" />
        </div>
      </div>
    </div>
  );
};
