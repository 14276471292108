import { useRef, useState } from "react";
import { streamTTSMessage } from "@/data/message";
import { MessageVoiceContext } from "./MessageVoiceContext";
import type { ReactNode } from "react";

export const MessageVoiceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [playingMessageId, setPlayingMessageId] = useState<string | null>(null);

  const playbackRef = useRef({
    audioContext: null as AudioContext | null,
    chunks: [] as Uint8Array[],
    isDecoding: false,
    audioElementFromUrl: null as HTMLAudioElement | null,
    streamingDone: false,
  });

  const isPlaying = playingMessageId !== null;

  const playMessage = async (messageId: string) => {
    if (isPlaying) {
      return;
    }

    setPlayingMessageId(messageId);
    const pb = playbackRef.current;

    pb.audioContext = null;
    pb.chunks = [];
    pb.isDecoding = false;
    pb.audioElementFromUrl = null;
    pb.streamingDone = false;

    try {
      const result = await streamTTSMessage(messageId, chunk => {
        // handle streaming response
        pb.chunks.push(chunk);

        if (!pb.isDecoding) {
          void decodeAndPlayNextChunk();
        }
      });

      // handle JSON response
      if (result.url) {
        pb.audioElementFromUrl = new Audio(result.url);

        pb.audioElementFromUrl.play().catch(err => {
          console.error("Error playing audio from URL:", err);
          stopPlayback();
        });

        pb.audioElementFromUrl.onended = () => stopPlayback();

        return;
      }
    } catch (error) {
      console.error("Error streaming voice:", error);

      stopPlayback();
    } finally {
      if (!pb.isDecoding && !pb.audioElementFromUrl) {
        void decodeAndPlayNextChunk(true);
      } else {
        pb.streamingDone = true;
      }
    }
  };

  const decodeAndPlayNextChunk = async (final = false) => {
    const pb = playbackRef.current;
    if (!pb.audioContext) {
      pb.audioContext = new AudioContext();
    }

    pb.isDecoding = true;

    if (pb.chunks.length === 0) {
      pb.isDecoding = false;
      if (final || pb.streamingDone) {
        stopPlayback();
      }
      return;
    }

    const nextChunk = pb.chunks.shift()!;
    try {
      const decoded = await pb.audioContext.decodeAudioData(nextChunk.buffer);
      const source = pb.audioContext.createBufferSource();
      source.buffer = decoded;
      source.connect(pb.audioContext.destination);

      source.onended = () => {
        pb.isDecoding = false;
        if (pb.chunks.length === 0 && (final || pb.streamingDone)) {
          stopPlayback();
        } else {
          void decodeAndPlayNextChunk(final);
        }
      };

      source.start();
    } catch (err) {
      console.error("decodeAudioData error:", err);
      pb.isDecoding = false;
      void decodeAndPlayNextChunk(final);
    }
  };

  const stopPlayback = () => {
    setPlayingMessageId(null);
    const pb = playbackRef.current;

    // stop streaming playback
    if (pb.audioContext) {
      pb.audioContext.close().catch(e => console.error("Error closing AudioContext:", e));
      pb.audioContext = null;
    }

    // stop url-based playback
    if (pb.audioElementFromUrl) {
      pb.audioElementFromUrl.pause();
      pb.audioElementFromUrl.src = "";
      pb.audioElementFromUrl = null;
    }

    pb.chunks = [];
    pb.isDecoding = false;
    pb.streamingDone = false;
  };

  return (
    <MessageVoiceContext.Provider value={{ playMessage, stopPlayback, playingMessageId, isPlaying }}>
      {children}
    </MessageVoiceContext.Provider>
  );
};
