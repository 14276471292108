import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";

const StatsTrendIndicator = ({ trend, subtitle }: { trend: number | null | undefined; subtitle?: string }) => {
  if (trend === null || trend === undefined) {
    return null;
  }

  const trendIcons = {
    up: {
      icon: <Icons.Arrow className="size-3 rotate-[135deg] text-green-500 lg:size-5" />,
    },
    down: {
      icon: <Icons.Arrow className="size-3 -rotate-[135deg] text-red-500 lg:size-5" />,
    },
    neutral: {
      icon: <div className="h-[1px] w-2 rounded-[1px] bg-neutral-400" />,
    },
  };

  const getTrendType = (value: number) => {
    if (value === 0) {
      return "neutral";
    }

    if (value < 0) {
      return "down";
    }

    return "up";
  };

  const getPercentValue = (value: number) => {
    if (value === 0) {
      return 0;
    }

    return Math.abs(value).toFixed(2);
  };

  return (
    <div className="flex items-center">
      {trendIcons[getTrendType(trend)].icon}
      <p
        className={cn("ml-1 text-xs font-medium sm:text-xs", {
          "text-green-500": getTrendType(trend) === "up",
          "text-red-500": getTrendType(trend) === "down",
          "text-neutral-400": getTrendType(trend) === "neutral",
        })}
      >
        {`${getPercentValue(trend)}% ${subtitle ?? ""}`}
      </p>
    </div>
  );
};

export default StatsTrendIndicator;
