import { DraggableTableHeaderCell } from "@/components/ui/draggable-table";
import { Icons } from "@/components/ui/icons";
import type { LeadStatsHeader } from "@/types/stats";

type Props = {
  iconKey?: LeadStatsHeader["iconKey"];
  children: React.ReactNode;
};

export const StatsLeadsTableHeaderCell = ({ iconKey, children }: Props) => {
  const IconComponent = iconKey ? Icons[iconKey] : null;

  return (
    <DraggableTableHeaderCell className="flex h-full w-full items-center justify-start gap-2 border-r p-2 text-base text-neutral-750">
      {IconComponent && <IconComponent className="size-6 p-1" />}

      {children}
    </DraggableTableHeaderCell>
  );
};
