import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useMessageVoice } from "@/contexts/MessageVoiceContext/useMessageVoice";
import type { Message } from "@/types/conversation";

type PlayMessageVoiceProps = {
  messageId: Message["_id"];
};

export const PlayMessageVoice: React.FC<PlayMessageVoiceProps> = ({ messageId }) => {
  const { playMessage, playingMessageId, isPlaying, stopPlayback } = useMessageVoice();

  const handlePlay = () => {
    if (isCurrentPlaying) {
      stopPlayback();
    } else {
      void playMessage(messageId);
    }
  };

  const isCurrentPlaying = playingMessageId === messageId;

  return (
    <Tooltip>
      <TooltipTrigger onClick={handlePlay}>
        <Icons.PlaySound
          className={cn(
            "size-[26px] flex-shrink-0 cursor-pointer p-1 text-neutral-600 hover:text-neutral-500",
            isCurrentPlaying
              ? "hover:neutral-500 text-black"
              : isPlaying
                ? "cursor-not-allowed text-neutral-200 hover:text-neutral-200"
                : "text-neutral-400 hover:text-neutral-400"
          )}
        />
      </TooltipTrigger>
      <TooltipContent>{isCurrentPlaying ? "Stop playing message" : "Play message"}</TooltipContent>
    </Tooltip>
  );
};
