import type { LeadStatsHeader } from "@/types/stats";

export const DEFAULT_LEAD_STATS_ICON_KEY: LeadStatsHeader["iconKey"] = "Component";

export const StaticTableHeaders = {
  AGENT: "agent",
  DATE: "date",
} as const;

export const PrimaryLeadGenFieldsMap: LeadStatsHeader[] = [
  {
    key: "name",
    iconKey: "User",
  },
  {
    key: "website",
    iconKey: "Globe",
  },
  {
    key: "email",
    iconKey: "Mail",
  },
  {
    key: "address",
    iconKey: "House",
  },
  {
    key: "phone",
    iconKey: "Phone",
  },
  {
    key: "zip code",
    iconKey: "Locate",
  },
  {
    key: "country",
    iconKey: "Flag",
  },
  {
    key: "city",
    iconKey: "Building",
  },
];

export const StatsLeadsKeysToOmit = ["id"];
