import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { OpenApiSchema } from "@/types/open-api";
import { baseURL } from "../initAxios";

// not a standard API path, so it doesn't belong in apiPaths.ts
const openApiUrl = `${baseURL.replace("/api/v1", "")}/download-openapi`;

export const getOpenApi = async (): Promise<OpenApiSchema> => {
  const response = await axios.get(openApiUrl);
  return response.data as OpenApiSchema;
};

export const useGetOpenApi = () => {
  return useQuery({
    queryKey: ["openApi"],
    queryFn: getOpenApi,
  });
};
