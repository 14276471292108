import { Avatar } from "@/components/Avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { LinkButton } from "@/components/ui/link-button";
import { isAgentInsideArray } from "@/lib/isAgentInsideArray";
import { cn } from "@/lib/utils";
import type { Agent } from "@/types/agent";
import type { Dispatch, SetStateAction } from "react";

type Props = {
  selectedAgents: Agent[];
  setSelectedAgents: Dispatch<SetStateAction<Agent[]>>;
  allAgents: Agent[] | undefined;
  disabled?: boolean;
};

export const StatsAgentsPicker = ({ selectedAgents, setSelectedAgents, allAgents, disabled }: Props) => {
  const getIsAgentSelected = (agentId: Agent["_id"]) => isAgentInsideArray(agentId, selectedAgents);
  const toggleAgentSelection = (agentId: Agent["_id"]) => {
    setSelectedAgents(prevSelectedAgents =>
      prevSelectedAgents.some(agent => agent._id === agentId)
        ? prevSelectedAgents.filter(selectedAgent => selectedAgent._id !== agentId)
        : [...prevSelectedAgents, allAgents!.find(agent => agent._id === agentId)!]
    );
  };

  const areAllAgentsSelected = allAgents?.length === selectedAgents.length;
  const isNoAgentSelected = selectedAgents.length === 0;

  const handleSelectAllAgents = () => {
    setSelectedAgents(allAgents ?? []);
  };
  const handleUnselectAllAgents = () => {
    setSelectedAgents([]);
  };

  const getAgentsDropdownTitle = () => {
    if (isNoAgentSelected) {
      return "Select Agents";
    }

    if (areAllAgentsSelected) {
      return "All Agents";
    }

    if (selectedAgents.length === 1) {
      return (
        <div className="flex items-center gap-1">
          <Avatar src={selectedAgents[0].avatar} size="custom" className={cn("h-5 w-5")} />
          {selectedAgents[0].name}
        </div>
      );
    }

    const maxItemsToShow = 4;
    const exceededNumber = selectedAgents.length - maxItemsToShow;
    const isExceeded = exceededNumber > 0;

    return (
      <div className="flex items-center gap-1">
        {selectedAgents.slice(0, 4).map(agent => (
          <Avatar key={agent._id} src={agent.avatar} size="custom" className={cn("h-5 w-5")} />
        ))}
        {isExceeded && <p className="text-sm font-medium">{`+${exceededNumber}`}</p>}
      </div>
    );
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        disabled={disabled}
        className="flex items-center gap-1 rounded-lg px-3 py-1 text-sm shadow-sm disabled:cursor-not-allowed disabled:opacity-50"
      >
        {getAgentsDropdownTitle()}
        <Icons.ChevronRight className="h-4 w-4 rotate-90" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => e.stopPropagation()}
        align="end"
        className="flex max-w-52 flex-col items-end gap-1 border border-primary-400 p-2.5"
      >
        <div className="mb-1 flex flex-wrap gap-1">
          {allAgents?.map(agent => {
            const isSelected = getIsAgentSelected(agent._id);

            return (
              <DropdownMenuItem
                key={agent._id}
                className={cn(
                  "flex cursor-pointer items-center gap-1 rounded-[4px] border border-neutral-200 bg-neutral-100 px-2 py-[2px] text-neutral-500",
                  {
                    "border-primary-400 bg-primary-100 text-neutral-600": isSelected,
                  }
                )}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleAgentSelection(agent._id);
                }}
              >
                <Avatar
                  src={agent.avatar}
                  size="custom"
                  className={cn("h-5 w-5", {
                    "grayscale filter": !isSelected,
                  })}
                />
                {agent.name}
              </DropdownMenuItem>
            );
          })}
        </div>

        {isNoAgentSelected ? (
          <LinkButton onClick={handleSelectAllAgents}>Select all</LinkButton>
        ) : (
          <LinkButton onClick={handleUnselectAllAgents}>Unselect all</LinkButton>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
