import { useNavigate } from "react-router";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { cn } from "@/lib/utils";
import AgentDetailsWrapper from "@/components/agentTiles/AgentDetails/AgentDetailsWrapper";
import { Icons } from "@/components/ui/icons";
import type { CarouselApi } from "@/components/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import type { ChatAgent } from "@/types/conversation";
import { useEffect, useMemo, useState } from "react";
import { useGetUser } from "@/data/queries/useGetUser";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { IconButton } from "@/components/ui/icon-button";
import AgentSearchDetailsWrapper from "@/components/agentTiles/AgentSearchDetails/AgentSearchDetailsWrapper";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import type { AgentMode } from "@/types/agent";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

type MyAgentDetailsProps = {
  agents: ChatAgent[];
  isLoading: boolean;
  isAgentDetailsOpen?: boolean;
  setIsAgentDetailsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  activeAgentIndex: number;
  setActiveAgentIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const MyAgentDetails = ({
  agents,
  isLoading,
  isAgentDetailsOpen,
  setIsAgentDetailsOpen,
  activeAgentIndex,
  setActiveAgentIndex,
}: MyAgentDetailsProps) => {
  const navigate = useNavigate();
  const { isGuestUser } = useIsGuestUser();
  const { agentMode, handleAgentMode } = useChatContext();

  const { user } = useGetUser({
    enabled: !isGuestUser,
  });
  const { isMobile } = useMediaQueriesContext();

  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const [isHoveredSearchButton, setIsHoveredSearchButton] = useState(false);

  const isActiveSearchModeAvailable = useMemo(() => {
    const agentsKnowledgeCount = agents.map(agent => Object.values(agent.embedKnowledges).flat()).flat().length;

    return agentsKnowledgeCount / agents.length >= 5;
  }, [agents]);

  useEffect(() => {
    if (activeAgentIndex === undefined || activeAgentIndex === null) {
      return;
    }

    carouselApi?.scrollTo(activeAgentIndex);
  }, [activeAgentIndex, carouselApi]);

  const isMyAgent = useMemo(() => {
    if (activeAgentIndex === undefined) {
      return;
    }

    return agents[activeAgentIndex]?.creator === user?._id;
  }, [activeAgentIndex, agents, user?._id]);

  if (isLoading) {
    return (
      <div className="relative size-full border-neutral-200 p-5 lg:w-[36%] lg:border-r">
        <LoadingSpinner className={cn("absolute-center", "top-1/3")} />
      </div>
    );
  }

  if (!agents.length) {
    return (
      <div className="p-10">
        <p>There was an error when retrieving agent data.</p>
      </div>
    );
  }

  const onBackClick = () => {
    if (isAgentDetailsOpen) {
      setIsAgentDetailsOpen?.(false);
    } else {
      navigate(-1);
    }
  };

  const hoverSearchButton = () => {
    if (isActiveSearchModeAvailable) {
      return;
    }
    setIsHoveredSearchButton(!isHoveredSearchButton);
  };

  return (
    <div className="flex w-full max-w-[100dvw] flex-col gap-4 border-neutral-200 px-6 py-4 lg:w-[36%] lg:min-w-[22.5rem] lg:max-w-[38rem] lg:border-r">
      <div className="flex justify-between">
        <div className="flex flex-row items-center gap-2">
          <IconButton onClick={onBackClick} icon={<Icons.Arrow />} size="medium" variant="tertiary">
            <span className="sr-only">go back</span>
          </IconButton>
          <span className="text-2xl font-bold">Agent</span>
        </div>

        <Tooltip open={isHoveredSearchButton}>
          <TooltipTrigger>
            <ToggleGroup
              type="single"
              value={agentMode}
              defaultValue="chat"
              onValueChange={value => {
                if (!value) {
                  return;
                }
                handleAgentMode(value as AgentMode);
              }}
              orientation="horizontal"
            >
              <ToggleGroupItem value="chat">Chat</ToggleGroupItem>
              <ToggleGroupItem
                value="search"
                disabled={!isActiveSearchModeAvailable}
                onMouseOver={hoverSearchButton}
                onMouseOut={hoverSearchButton}
              >
                Search
              </ToggleGroupItem>
            </ToggleGroup>
          </TooltipTrigger>
          {!isActiveSearchModeAvailable && (
            <TooltipContent side="bottom" sideOffset={5}>
              5 knowledge needed to use search mode
            </TooltipContent>
          )}
        </Tooltip>
      </div>
      <Carousel
        className="h-full overflow-y-scroll pb-10"
        setApi={setCarouselApi}
        onActiveSlideChange={slideIndex => setActiveAgentIndex(slideIndex)}
        opts={{
          watchDrag: isMobile,
        }}
      >
        <CarouselContent>
          {agents.map(agent => {
            if (!agent) {
              return;
            }

            return (
              <CarouselItem key={agent?._id} className="h-fit basis-full" containerClassName="h-fit basis-full">
                {agentMode === "chat" ? (
                  <AgentDetailsWrapper
                    agent={agent}
                    isAgentDetailsOpen={isAgentDetailsOpen}
                    setIsAgentDetailsOpen={setIsAgentDetailsOpen}
                    isMyAgent={isMyAgent}
                    agents={agents}
                    activeAgentIndex={activeAgentIndex}
                  />
                ) : (
                  <AgentSearchDetailsWrapper
                    agent={agent}
                    isAgentDetailsOpen={isAgentDetailsOpen}
                    setIsAgentDetailsOpen={setIsAgentDetailsOpen}
                    isMyAgent={isMyAgent}
                    agents={agents}
                    activeAgentIndex={activeAgentIndex}
                  />
                )}
              </CarouselItem>
            );
          })}
        </CarouselContent>

        {agents.length > 1 && (
          <div className="relative mt-5 flex items-center justify-between">
            <CarouselPrevious className="static translate-x-0 translate-y-0" icon={<Icons.Arrow className="" />} />
            <CarouselDots className="static translate-x-0" />
            <CarouselNext
              className="static translate-x-0 translate-y-0"
              icon={<Icons.Arrow className="rotate-180 " />}
            />
          </div>
        )}
      </Carousel>
    </div>
  );
};
