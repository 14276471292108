export interface StyleOptions {
  titleColor: string;
  borderColor?: string;
  backgroundColor?: string;
  borderColorHover?: string;
  backgroundColorHover?: string;
  headerBorderColorExpanded?: string;
  bodyBorderColorExpanded?: string;
  backgroundColorExpanded?: string;
}

export function fixRapiDocStyles(options?: StyleOptions): MutationObserver {
  const {
    titleColor = "black",
    borderColor = "transparent",
    backgroundColor = "transparent",
    borderColorHover = "transparent",
    backgroundColorHover = "transparent",
    headerBorderColorExpanded = "transparent",
    bodyBorderColorExpanded = "transparent",
    backgroundColorExpanded = "transparent",
  } = options || {};

  const fixRequestPanelStyle = (): boolean => {
    const rapiDocElement = document.querySelector("rapi-doc");
    if (!rapiDocElement?.shadowRoot) {
      return false;
    }

    const requestPanel = rapiDocElement.shadowRoot.querySelector<HTMLDivElement>("div.view-mode-request");
    if (!requestPanel) {
      return false;
    }

    requestPanel.setAttribute(
      "style",
      "display: block !important; min-height: auto !important; flex-direction: initial !important; overflow: visible !important;"
    );

    return true;
  };

  const fixEndpointHeads = () => {
    const rapiDocElement = document.querySelector("rapi-doc");
    if (!rapiDocElement?.shadowRoot) {
      return;
    }

    if (rapiDocElement.shadowRoot.querySelector("#custom-endpoint-head-styles")) {
      return;
    }

    const style = document.createElement("style");
    style.id = "custom-endpoint-head-styles";

    style.textContent = `
      /* Non-hover & non-expanded styles */
      .endpoint-head.get, .endpoint-head.post, .endpoint-head.put,
      .endpoint-head.delete, .endpoint-head.patch, .endpoint-head.options,
      .endpoint-head.head, .endpoint-head.connect, .endpoint-head.trace {
        background-color: ${backgroundColor} !important;
        border: 1px solid ${borderColor} !important;
        box-shadow: none !important;
        color: inherit !important;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
    
      /* Hover styles */
      .endpoint-head.get:hover, .endpoint-head.post:hover, .endpoint-head.put:hover,
      .endpoint-head.delete:hover, .endpoint-head.patch:hover, .endpoint-head.options:hover,
      .endpoint-head.head:hover, .endpoint-head.connect:hover, .endpoint-head.trace:hover {
        background-color: ${backgroundColorHover} !important;
        border: 1px solid ${borderColorHover} !important;
        box-shadow: none !important;
        color: inherit !important;
      }
    
      /* Expanded styles */
      .endpoint-head.get.expanded, .endpoint-head.post.expanded, .endpoint-head.put.expanded,
      .endpoint-head.delete.expanded, .endpoint-head.patch.expanded, .endpoint-head.options.expanded,
      .endpoint-head.head.expanded, .endpoint-head.connect.expanded, .endpoint-head.trace.expanded {
        background-color: ${backgroundColorExpanded} !important;
        border: 1px solid ${headerBorderColorExpanded} !important;
        box-shadow: none !important;
        color: inherit !important;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
        
      .endpoint-head .method.get,
      .endpoint-head .method.post,
      .endpoint-head .method.put,
      .endpoint-head .method.delete,
      .endpoint-head .method.patch,
      .endpoint-head .method.options,
      .endpoint-head .method.head,
      .endpoint-head .method.connect,
      .endpoint-head .method.trace {
        border: 1x solid ${borderColor} !important;
      }

      .m-endpoint .endpoint-body.get,
      .m-endpoint .endpoint-body.post,
      .m-endpoint .endpoint-body.put,
      .m-endpoint .endpoint-body.delete,
      .m-endpoint .endpoint-body.patch,
      .m-endpoint .endpoint-body.options,
      .m-endpoint .endpoint-body.head,
      .m-endpoint .endpoint-body.connect,
      .m-endpoint .endpoint-body.trace {
        border: 1px solid ${bodyBorderColorExpanded} !important;
      }

      /* Other styles */
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }

      .param-name {
        margin: 12px !important;
        color: ${titleColor} !important;
      }

      #api-title {
        color: ${titleColor} !important;
      }

      .section-tag-header {
        display: none;
      }
      
      #operations-top + div {
        visibility: hidden;
      }

    `;

    rapiDocElement.shadowRoot.appendChild(style);
  };

  function fixNestedShadowRoot(): void {
    const rapiDocElement = document.querySelector("rapi-doc");
    if (!rapiDocElement?.shadowRoot) {
      return;
    }

    const nestedElements = rapiDocElement.shadowRoot.querySelectorAll("api-request");

    nestedElements.forEach(nestedEl => {
      const nestedShadow = (nestedEl as HTMLElement & { shadowRoot?: ShadowRoot }).shadowRoot;
      if (!nestedShadow) {
        return;
      }

      if (!nestedShadow.querySelector("#custom-param-style")) {
        const style = document.createElement("style");
        style.id = "custom-param-style";
        style.textContent = `
          .param-name {
            margin-top: 13px !important;
          }
          .param-type {
            margin-top: 3px !important;
            padding-bottom: 11px !important;
          }
        `;
        nestedShadow.appendChild(style);
      }
    });
  }

  const applyAllFixes = () => {
    fixRequestPanelStyle();
    fixEndpointHeads();
    fixNestedShadowRoot();
  };

  applyAllFixes();

  const observer = new MutationObserver(() => {
    applyAllFixes();
  });

  const rapiDocElement = document.querySelector("rapi-doc");
  if (rapiDocElement?.shadowRoot) {
    observer.observe(rapiDocElement.shadowRoot, {
      childList: true,
      subtree: true,
    });
  }

  return observer;
}
