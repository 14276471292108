import type { OpenApiSchema } from "@/types/open-api";

export const prefillConversationId = (openApiJson: OpenApiSchema, conversationId: string | null, isMobile: boolean) => {
  if (!conversationId) {
    return openApiJson;
  }

  const modifiedOpenApi = JSON.parse(JSON.stringify(openApiJson)) as OpenApiSchema;

  const conversationEndpoints = [
    "/api/v1/access/conversations/{id}/messagesse",
    "/api/v1/access/conversations/{id}/message",
    "/api/v1/access/conversations/{id}/update-context",
    "/api/v1/access/agents/{id}/conversations/{cid}",
  ];

  for (const path of conversationEndpoints) {
    if (modifiedOpenApi.paths[path]) {
      for (const method in modifiedOpenApi.paths[path]) {
        if (modifiedOpenApi.paths[path][method].parameters) {
          (
            modifiedOpenApi.paths[path][method].parameters as {
              name: string;
              in: string;
              example?: string;
            }[]
          ).forEach(param => {
            if (param.in === "path" && (path.includes("conversations/{id}") || param.name === "cid")) {
              param.example = conversationId;
            }
          });
        }

        const currentDesc = modifiedOpenApi.paths[path][method].description;
        const noteDesktop =
          "\n\n**Note:** The example conversation ID used is the currently open conversation (right panel).";
        const noteMobile = "\n\n**Note:** The example conversation ID used is the most recently open conversation.";
        const note = isMobile ? noteMobile : noteDesktop;

        if (typeof currentDesc === "string") {
          modifiedOpenApi.paths[path][method].description = currentDesc + note;
        } else {
          modifiedOpenApi.paths[path][method].description = note.trimStart();
        }
      }
    }
  }

  return modifiedOpenApi;
};
