import StatsDateRangePicker from "@/components/StatsDateRangePicker/StatsDateRangePicker";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { ROUTES } from "@/constants/routes";
import { StatsAgentsPicker } from "@/pages/Stats/components/StatsAgentsPicker/StatsAgentsPicker";
import type { Agent } from "@/types/agent";
import type { DateRangeType } from "@/types/datepicker";
import { Link } from "react-router-dom";
import type { LeadStatsHeader } from "@/types/stats";
import { Button } from "@/components/ui/button";
import { StatsLeadsTableColumnsPicker } from "./components/StatsLeadsTableColumnsPicker";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";

type Props = {
  disabled: boolean;
  currentRange: DateRangeType;
  onCurrentRangeChange: (range: DateRangeType) => void;
  startDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  endDate: Date | null;
  onEndDateChange: (date: Date | null) => void;
  selectedAgents: Agent[];
  setSelectedAgents: React.Dispatch<React.SetStateAction<Agent[]>>;
  allAgents: Agent[];
  leadsCount: number;
  searchValue: string;
  setSeachValue: React.Dispatch<React.SetStateAction<string>>;
  allTableHeaders: LeadStatsHeader[];
  selectedTableHeaders: LeadStatsHeader[];
  setSelectedTableHeaders: React.Dispatch<React.SetStateAction<LeadStatsHeader[]>>;
  isSelectingEnabled: boolean;
  onEnableSelecting: () => void;
  onDisableSelecting: () => void;
  onDownloadSelectedClick: () => void;
  isDownloadDisabled: boolean;
};

export const StatsLeadsHeader = ({
  disabled,
  currentRange,
  onCurrentRangeChange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  selectedAgents,
  setSelectedAgents,
  allAgents,
  leadsCount,
  searchValue,
  setSeachValue,
  allTableHeaders,
  selectedTableHeaders,
  setSelectedTableHeaders,
  isSelectingEnabled,
  onEnableSelecting,
  onDisableSelecting,
  onDownloadSelectedClick,
  isDownloadDisabled,
}: Props) => {
  const isMobile = !useTailwindMediaQuery("sm");

  return (
    <div className="pr-1 pt-4">
      <div className="flex w-full flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex items-center gap-4 self-start">
          <Link to={ROUTES.stats}>
            <IconButton size="medium" variant="tertiary" icon={<Icons.ChevronLeft />} />
          </Link>
          <div>
            <h1 className="text-xl font-bold leading-[26px] text-neutral-750 sm:text-2xl sm:leading-10">
              Lead Generation
            </h1>
            <p className="text-sm font-normal text-secondary-400">Here are the leads generated.</p>
          </div>
        </div>
        <div className="flex w-full flex-col items-center gap-2 md:w-fit md:flex-row md:gap-[30px]">
          <div className="relative w-full md:w-fit">
            <Icons.Search className="absolute left-4 top-1/2 z-10 size-6 -translate-y-1/2 text-primary-400" />
            <Input
              disabled={disabled}
              value={searchValue}
              onChange={e => setSeachValue(e.target.value)}
              placeholder="Search"
              inputClassName="rounded-full hover:ring-1 hover:ring-primary-600 hover:ring-offset-2 px-12 border-transparent focus-visible:border-transparent sm:px-12"
              handleClear={() => setSeachValue("")}
            />
          </div>

          <div className="flex w-full items-center justify-end gap-2 md:w-fit md:flex-wrap">
            <StatsDateRangePicker
              selectedDateRangeType={currentRange}
              onSelectedDateRangeTypeChange={onCurrentRangeChange}
              startDate={startDate}
              onStartDateChange={onStartDateChange}
              endDate={endDate}
              onEndDateChange={onEndDateChange}
              className="md:w-fit"
              disabled={disabled}
            />

            {!isMobile && isSelectingEnabled ? (
              <div className="flex items-center gap-2">
                <Button prefixIcon={<Icons.Download />} onClick={onDownloadSelectedClick} disabled={isDownloadDisabled}>
                  Download selected
                </Button>
                <Button variant="tertiary" onClick={onDisableSelecting}>
                  Cancel
                </Button>
              </div>
            ) : (
              <IconButton icon={<Icons.Download />} variant="tertiary" onClick={onEnableSelecting} />
            )}
          </div>

          {isMobile && isSelectingEnabled && (
            <div className="flex w-full items-center gap-2">
              <Button
                prefixIcon={<Icons.Download />}
                onClick={onDownloadSelectedClick}
                disabled={isDownloadDisabled}
                className="w-full"
              >
                Download selected
              </Button>
              <Button variant="tertiary" onClick={onDisableSelecting} className="w-full">
                Cancel
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 flex items-center justify-between gap-6 sm:justify-start">
        <h5 className="text-xl leading-[30px] text-neutral-400">{`(${leadsCount}) leads`}</h5>

        <div className="flex flex-col items-end gap-6 sm:flex-row sm:items-center">
          <StatsAgentsPicker
            selectedAgents={selectedAgents}
            setSelectedAgents={setSelectedAgents}
            allAgents={allAgents}
            disabled={disabled}
          />

          <StatsLeadsTableColumnsPicker
            selectedTableHeaders={selectedTableHeaders}
            setSelectedTableHeaders={setSelectedTableHeaders}
            allTableHeaders={allTableHeaders}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
