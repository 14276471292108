export type VoiceProfile = {
  language: VoiceProfileLanguage | undefined;
  gender: VoiceProfileGender | undefined;
  accent: VoiceProfileAccent | null;
  customVoices: string[];
  activeCustomVoice: string | null;
};

export enum VoiceProfileLanguage {
  EN = "en", // english
  ES = "es", // spanish
  FR = "fr", // french
  DE = "de", // german
  IT = "it", // italian
  PT = "pt", // portugese
  PL = "pl", // polish
  TR = "tr", // turkish
  RU = "ru", // russian
  NL = "nl", // dutch
  CS = "cs", // czech
  AR = "ar", // arabic
  ZH_CN = "zh-cn", // chinese
  HU = "hu", // hungarian
  KO = "ko", // korean
  JA = "ja", // japanese
  HI = "hi", // hindi
}

export const VoiceProfileLanguageLabels = {
  en: "English",
  es: "Spanish",
  fr: "French",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
  pl: "Polish",
  tr: "Turkish",
  ru: "Russian",
  nl: "Dutch",
  cs: "Czech",
  ar: "Arabic",
  "zh-cn": "Chinese (CHN)",
  hu: "Hungarian",
  ko: "Korean",
  ja: "Japanese",
  hi: "Hindi",
};

export enum VoiceProfileGender {
  MALE = "male",
  FEMALE = "female",
}

export enum VoiceProfileAccent {
  US = "US",
  GB = "GB",
  ES = "ES",
  MX = "MX",
  NONE = "",
}

export const voiceProfileAccentsPerLanguage = {
  en: [VoiceProfileAccent.US, VoiceProfileAccent.GB],
  es: [VoiceProfileAccent.ES, VoiceProfileAccent.MX],
  fr: [VoiceProfileAccent.NONE],
  de: [VoiceProfileAccent.NONE],
  it: [VoiceProfileAccent.NONE],
  pl: [VoiceProfileAccent.NONE],
  tr: [VoiceProfileAccent.NONE],
  ru: [VoiceProfileAccent.NONE],
  nl: [VoiceProfileAccent.NONE],
  cs: [VoiceProfileAccent.NONE],
  ar: [VoiceProfileAccent.NONE],
  hu: [VoiceProfileAccent.NONE],
  ko: [VoiceProfileAccent.NONE],
  ja: [VoiceProfileAccent.NONE],
  hi: [VoiceProfileAccent.NONE],
};

export const defaultVoiceProfile = {
  language: VoiceProfileLanguage.EN,
  accent: VoiceProfileAccent.US,
  gender: VoiceProfileGender.MALE,
  customVoices: [],
  activeCustomVoice: null,
} as VoiceProfile;

export const VoiceProfileAccentLabels = {
  [VoiceProfileAccent.US]: "United States",
  [VoiceProfileAccent.GB]: "Great Britain",
  [VoiceProfileAccent.ES]: "Spain",
  [VoiceProfileAccent.MX]: "Mexico",
  [VoiceProfileAccent.NONE]: "None",
};
