import { agentCreationId } from "@/constants/agentCreationId";
import type { Parameter } from "./tools";
import { leadGenerationRequiredParams } from "@/constants/leadGenerationContants";
import type { Deployments } from "./user-agent";
import type { TypeFromConstObject } from "./types";
import type { ExternalAgentPlatform } from "./origin";
export type AgentMode = "chat" | "search" | "ecommerce";
import { defaultVoiceProfile } from "./voice";
import type { VoiceProfile } from "./voice";

export type Agent = {
  _id: string;
  creator: string;
  isActive: boolean;
  agentType: AgentType;
  name: string;
  avatar: string;
  contacts: string[];
  blocked: string[];
  blockedFrom: string[];
  deleted: false;
  createdAt: string;
  updatedAt: string;
  state: AgentState;
  publishedToCommunity: boolean;
  description?: string;
  deployments?: Deployments[];
  websiteEmbeddingKey?: string;
  externalKnowledgeIntegrations:
    | {
        google_drive?: string;
      }
    | undefined;
};

export const AgentTypes = {
  HUMANLIKE: "humanlike",
  ECOMMERCE: "ecommerce",
} as const;

export type AgentType = TypeFromConstObject<typeof AgentTypes>;

export enum AgentState {
  AGENT_READY = "AGENT_READY",
  AGENT_UPDATING = "AGENT_UPDATING",
  AGENT_ERROR = "AGENT_ERROR",
  AGENT_DRAFT = "AGENT_DRAFT",
  AGENT_CREATING = "AGENT_CREATING",
  AGENT_REMOVING = "AGENT_REMOVING",
}

export type AgentConfig = {
  _id: string;
  botId: string;
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: (EmbedKnowledges | FutureEmbedKnowledges | FutureEmbedKnowledgeDocument)[];
    faq: EmbedKnowledges[];
    externalHelpers: EmbedExternalKnowledge[];
  };
  inMemoryKnowledge: {
    about: string;
    instruction: string;
    onboardingList: Onboarding[];
  };
  company: string;
  temperature: number;
  llmModel: string;
  definedActions?: { greeting: { message: string }[]; predefined: Predefined[]; _id: string };
  publish: {
    isConfigured: boolean;
    isPublished: boolean;
    allowedOrigins: { domain: string }[];
    isAllowedOriginsEnabled: boolean;
    chatStyle: {
      variant: StyleSetVariant;
      color: string;
      iconSize: StyleIconSize;
    };
    customBranding: {
      isActive: boolean;
      value: string;
    };
  };
  buildinFunctions: AgentBuildInFunction[];
  loadJs: boolean;
  voiceProfile?: VoiceProfile;
};

export type DomainLinks = { domain: string; links: EmbedKnowledges[] };

export enum StyleSetVariant {
  Rounded = "rounded",
  Square = "square",
  Classic = "classic",
}

export enum StyleChatColor {
  Neutral = "neutral",
  Yellow = "yellow",
  Red = "red",
  Orange = "orange",
  Blue = "blue",
  Green = "green",
  Purple = "purple",
  Black = "black",
}

export enum StyleIconSize {
  Small = "s",
  Medium = "m",
  Large = "l",
}

export type Onboarding = {
  content: string;
  description: string;
  _id: string;
  isNew?: boolean;
};

export type FullAgent = Agent & AgentConfig;

export type Predefined = {
  question: string;
  answer: string;
};

export type EmbedKnowledges = {
  name?: string | null | undefined;
  _id: string;
  creator: string;
  embedIdList: string[];
  knowledgeType: "WEB" | "DOC" | "FAQ";
  source: string;
  filename: string;
  description: string;
  content: string;
  parentId: string | null;
  status: "success" | "failed" | "refresh";
  createdAt: string;
  updatedAt: string;
  predefined?: boolean;
  externalSource?: ExternalSource;
  externalId?: string;
  folderId?: string;
};

export enum ExternalSource {
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  DROPBOX = "DROPBOX",
  ONEDRIVE = "ONEDRIVE",
}
// This structure is used for new folders that come from Google Drive
// It is necessary since it can't be mapped directly to the domain
export type FutureEmbedKnowledges = {
  _id?: string;
  isNewFolder?: boolean;
  externalId: string;
  name: string;
  mimeType: string;
  children: EmbedKnowledges[];
  status?: string;
  folderId?: string;
  parentId?: string;
  filename?: string;
};

export type FutureEmbedKnowledgeDocument = {
  externalId: string;
  filename: string;
  mimeType: string;
  name?: string;
  status?: string;
  parentId?: string;
};

export type EmbedExternalKnowledge = {
  _id?: string;
  externalSource: ExternalSource;
  externalId: string;
  id: string;
  name: string;
  folderId?: string;
};

export const KnowledgeStatus = {
  SUCCESS: "success",
  FAILED: "failed",
  REFRESH: "refresh",
} as const;

export type DocumentUploadResponse = { docId: string; filename: string; path: string };

export type AgentMutationPayload = {
  newData: FullAgent & { loadJs: boolean };
  prevData: FullAgent;
};

export type AgentLeadGeneration = {
  leadGeneration?: {
    enabled: boolean;
    params: Parameter[];
    emailVendor?: "" | "GMAIL";
    disableEmail?: boolean;
    webhook: {
      enabled: boolean;
      url: string;
      header?: string[];
      method?: string;
    };
  };
};

export type AgentGoogleCalendar = {
  googleCalendar?: {
    enabled: boolean;
    calendar: {
      calendarId: string;
      email: string;
    };
  };
};

export type AgentFormFields = FullAgent & AgentLeadGeneration & AgentGoogleCalendar;

export const agentFormDefaultValues: AgentFormFields = {
  _id: "",
  creator: "",
  isActive: false,
  agentType: AgentTypes.HUMANLIKE,
  name: "",
  avatar: "",
  contacts: [],
  blocked: [],
  blockedFrom: [],
  deleted: false,
  createdAt: "",
  updatedAt: "",
  state: AgentState.AGENT_READY,
  botId: "",
  embedKnowledges: {
    web: [],
    doc: [],
    faq: [],
    externalHelpers: [],
  },
  inMemoryKnowledge: {
    about: "",
    instruction: "",
    onboardingList: [],
  },
  company: "",
  temperature: 0,
  llmModel: "GPT-4o-mini",
  definedActions: {
    greeting: [],
    predefined: [],
    _id: "",
  },
  publish: {
    isConfigured: false,
    isPublished: false,
    allowedOrigins: [],
    isAllowedOriginsEnabled: false,
    chatStyle: {
      variant: StyleSetVariant.Rounded,
      color: StyleChatColor.Neutral,
      iconSize: StyleIconSize.Medium,
    },
    customBranding: {
      isActive: false,
      value: "",
    },
  },
  buildinFunctions: [],
  loadJs: false,
  publishedToCommunity: false,
  leadGeneration: {
    enabled: false,
    params: leadGenerationRequiredParams,
    emailVendor: "",
    disableEmail: false,
    webhook: {
      enabled: false,
      url: "",
      header: [""],
      method: "POST",
    },
  },
  googleCalendar: {
    enabled: false,
    calendar: {
      calendarId: "",
      email: "",
    },
  },
  externalKnowledgeIntegrations: {},
  voiceProfile: defaultVoiceProfile,
};

export const newDraftAgent: Agent = {
  _id: agentCreationId,
  creator: "",
  isActive: false,
  agentType: AgentTypes.HUMANLIKE,
  name: "",
  avatar: "",
  contacts: [],
  blocked: [],
  blockedFrom: [],
  deleted: false,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  state: AgentState.AGENT_DRAFT,
  publishedToCommunity: false,
  externalKnowledgeIntegrations: {},
};

export const agentBuildInFunctions = {
  IMAGE_GENERATE: "IMAGE_GENERATE",
  IMAGE_GENERATE_FLUX: "IMAGE_GENERATE_FLUX",
  BROWSE_WEB: "BROWSE_WEB",
  SEARCH_ENGINE: "SEARCH_ENGINE",
  EVENT_SCHEDULER: "EVENT_SCHEDULER",
  TEXT_EXTRACTION: "TEXT_EXTRACTION",
  VIDEO_GENERATE: "VIDEO_GENERATE",
  ATTACHMENT: "ATTACHMENT", // reading or parsing the attachment
} as const;

export type AgentBuildInFunction = (typeof agentBuildInFunctions)[keyof typeof agentBuildInFunctions];

export enum ExternalAgentStatus {
  INITIALIZED = "initialized",
  ONLINE = "online",
  OFFLINE = "offline",
  ERROR = "error",
  PAUSED = "paused",
}

export type ExternalAgentBase = {
  _id: string;
  creator: string;
  botId: Agent["_id"];
  active: boolean;
  token: string;
  createdAt: string;
  updatedAt: string;
  status: ExternalAgentStatus;
};

export type DiscordAgent = ExternalAgentBase & {
  platform: ExternalAgentPlatform.DISCORD;
  platformAttributes: { discordClientId: string };
};

export type SlackAgent = ExternalAgentBase & {
  platform: ExternalAgentPlatform.SLACK;
  platformAttributes: { temporaryId: string };
};

// WhatsApp
export type whatsAppMessageTemplate = {
  name: string;
  status: string;
  id: string;
};

export type WhatsAppAgent = ExternalAgentBase & {
  platform: ExternalAgentPlatform.WHATSAPP;
  platformAttributes: { phoneNumber: string; phoneNumberId: string; wabaId: string; wabaName: string };
  templates?: whatsAppMessageTemplate[];
};
export type InstagramAgent = ExternalAgentBase & {
  platform: ExternalAgentPlatform.INSTAGRAM;
  platformAttributes: { display_name: string; handle: string; user_id: string };
};

export type MessengerAgent = ExternalAgentBase & {
  platform: ExternalAgentPlatform.MESSENGER;
  platformAttributes: {
    display_name: string;
    pageId: string;
  };
};
export type ExternalAgent = DiscordAgent | SlackAgent | WhatsAppAgent | InstagramAgent | MessengerAgent;

export enum ShareIntegrationType {
  DISCORD = "discord",
}

export const ReportAgentReasons = {
  PORN: "Pornographic Content",
  EXAGGERATED: "Exaggerated for Attention",
  INAPPROPRIATE: "Inappropriate Content",
  ILLEGAL: "Illegal Content",
  OTHER: "Other",
} as const;

export type ReportAgentReason = TypeFromConstObject<typeof ReportAgentReasons>;
