import type { OpenApiSchema } from "@/types/open-api";

export const filterApiKey = (openApiJson: OpenApiSchema): OpenApiSchema => {
  const modifiedOpenApi = JSON.parse(JSON.stringify(openApiJson)) as OpenApiSchema;

  if (modifiedOpenApi.components?.securitySchemes?.ApiKeyAuth) {
    delete modifiedOpenApi.components.securitySchemes.ApiKeyAuth;
  }

  if (modifiedOpenApi.security) {
    modifiedOpenApi.security = modifiedOpenApi.security.filter(security => !security.ApiKeyAuth);
  }

  return modifiedOpenApi;
};
