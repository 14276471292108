import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { AppProviders } from "./AppProviders";
import { Login } from "./pages/Login/Login";
import { Register } from "./pages/Register/Register";
import { Stats } from "./pages/Stats/Stats";
import "./index.css";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { Help } from "./pages/Help/Help";
import { UpdatePassword } from "./pages/UpdatePassword/UpdatePassword";
import { ProtectedRoutes } from "./components/Routes/ProtectedRoutes";
import { authorizeAxiosClient } from "./data/initAxios";
import { ThirdPartyLogin } from "./pages/ThirdPartyLogin/ThirdPartyLogin";
import { Community } from "./pages/Community/Community";
import { CommunityProfile } from "./pages/Community/CommunityProfile";
import { SharedConversation } from "./pages/SharedConversation/SharedConversation";
import { Workspace } from "./pages/Workspace/Workspace";
import { CreateAgentPage } from "./pages/CreateAgent/CreateAgentPage";
import { EditAgentPage } from "./pages/EditAgent/EditAgentPage";
import { UpdatePasswordEnterprise } from "./pages/UpdatePasswordEnterprise/UpdatePasswordEnterprise";
import NewConversation from "./pages/NewConversation/NewConversation";
import Conversation from "./pages/Conversation/Conversation";
import { ErrorBoundaryComponent } from "./components/ErrorBoundaryComponent";
import { StatsProvider } from "./contexts/StatsContext/StatsProvider";
import { GuestRoutes } from "./components/Routes/GuestRoutes";
import { LoginRoutesDesktop } from "@/components/Routes/LoginRoutesDesktop";
import { ErrorBoundary } from "@sentry/react";
import { StatsLeads } from "./pages/StatsLeads/StatsLeads";

const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary fallback={<ErrorBoundaryComponent />}>
        <AppProviders>
          <Outlet />
        </AppProviders>
      </ErrorBoundary>
    ),
    children: [
      {
        element: <GuestRoutes />,
        children: [
          {
            path: ROUTES.community,
            element: <Community />,
          },
          {
            path: ROUTES.newConversation,
            element: <NewConversation />,
          },
        ],
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: ROUTES.home,
            element: <Navigate to={ROUTES.workspace} replace />,
          },

          {
            path: ROUTES.createAgent,
            element: <CreateAgentPage />,
          },

          {
            path: ROUTES.editAgent(":id"),
            element: <EditAgentPage />,
          },

          {
            path: ROUTES.workspace,
            element: <Workspace />,
          },
          {
            path: ROUTES.conversation(":conversationId"),
            element: <Conversation />,
          },

          {
            path: ROUTES.stats,
            element: (
              <StatsProvider>
                <Stats />
              </StatsProvider>
            ),
          },
          {
            path: ROUTES.statsLeads,
            element: (
              <StatsProvider>
                <StatsLeads />
              </StatsProvider>
            ),
          },
          {
            path: ROUTES.help,
            element: <Help />,
          },
          {
            path: ROUTES.communityProfile(":profileId"),
            element: <CommunityProfile />,
          },
        ],
      },
      {
        element: <LoginRoutesDesktop />,
        children: [
          {
            path: ROUTES.register,
            element: <Register />,
          },
          {
            path: ROUTES.login,
            element: <Login />,
          },
          {
            path: ROUTES.forgotPassword,
            element: <ForgotPassword />,
          },
          {
            path: ROUTES.updatePassword,
            element: <UpdatePassword />,
          },
          {
            path: ROUTES.updatePasswordEnterprise,
            element: <UpdatePasswordEnterprise />,
          },
          {
            path: ROUTES.thirdPartyLogin,
            element: <ThirdPartyLogin />,
          },
          {
            path: "*",
            element: <Navigate to={ROUTES.login} />,
          },
        ],
      },
      {
        element: <Outlet />,
        children: [
          {
            path: ROUTES.sharedConversation(":id"),
            element: <SharedConversation />,
          },
        ],
      },
    ],
  },
]);

export const App = () => {
  const token = localStorage.getItem("token");
  if (token) {
    authorizeAxiosClient({ token });
  }

  return <RouterProvider router={router} />;
};
