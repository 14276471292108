import type { Agent } from "@/types/agent";
import StatsTopCard from "../StatsTopCard";
import { useRef, useState } from "react";
import type { Origin } from "@/types/origin";
import { StatsSidePanelAgentPicker } from "./components/StatsSidePanelAgentPicker";
import { StatsSidePanelAgentPerformance } from "./components/StatsSidePanelAgentPerformance";
import { StatsSidePanelChannelPerformance } from "./components/StatsSidePanelChannelPerformance";
import { StatsSidePanelFooter } from "./components/StatsSidePanelFooter";
import { SidePanel } from "@/components/SidePanel/SidePanel";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  agentData: {
    selectedAgent: Agent;
    onChangeSelectedAgent: (agent: Agent) => void;
    messages: number;
    messagesTrend: number | null;
    conversations: number;
    conversationsTrend: number | null;
    satisfaction: number | null;
    satisfactionTrend: number | null;
    allAgents: Agent[];
    channels: {
      channel: Origin;
      value: number;
    }[];
  } | null;
};

export const StatsSidePanel = ({ isOpen, setIsOpen, agentData }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [footerHeight, setFooterHeight] = useState(0);

  return (
    <SidePanel ref={contentRef} isOpen={isOpen} setIsOpen={setIsOpen}>
      {!!agentData && (
        <>
          <div
            className="flex h-full flex-col gap-8"
            style={{
              paddingBottom: `${footerHeight}px`,
            }}
          >
            <div className="flex flex-wrap items-center justify-between gap-4">
              <h1 className="whitespace-nowrap text-xl font-bold text-neutral-750 @xl/main:text-2xl">
                How Agent performs
              </h1>
              <StatsSidePanelAgentPicker
                agent={agentData.selectedAgent}
                allAgents={agentData.allAgents}
                onChangeSelectedAgent={agentData.onChangeSelectedAgent}
              />
            </div>

            <div className="flex-1 overflow-y-auto pb-6">
              <div className="flex flex-col items-center gap-2 @md/main:flex-row">
                <StatsTopCard
                  title="Messages"
                  value={agentData.messages}
                  trend={agentData.messagesTrend}
                  hideTrendChart
                  animatedValueClassName="text-lg md:text-lg"
                />
                <StatsTopCard
                  title="Conversations"
                  value={agentData.conversations}
                  trend={agentData.conversationsTrend}
                  hideTrendChart
                  animatedValueClassName="text-lg md:text-lg"
                />
                <StatsTopCard
                  title="Satisfaction"
                  value={agentData.satisfaction ?? 0}
                  trend={agentData.satisfactionTrend}
                  hideTrendChart
                  animatedValueClassName="text-lg md:text-lg"
                />
              </div>

              <StatsSidePanelAgentPerformance agent={agentData.selectedAgent} />

              <StatsSidePanelChannelPerformance channels={agentData.channels} agent={agentData.selectedAgent} />
            </div>
          </div>

          <StatsSidePanelFooter setFooterHeight={setFooterHeight} agent={agentData.selectedAgent} />
        </>
      )}
    </SidePanel>
  );
};
