import { DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "../ui/input";
import { ChatHistoryDialogFoundBadge } from "./components/ChatHistoryDialogFoundBadge";
import StatsDateRangePicker from "../StatsDateRangePicker/StatsDateRangePicker";
import { HybridTooltipPopover } from "../HybridTooltipPopover";
import type { DateRangeType } from "@/types/datepicker";
import { cn } from "@/lib/utils";
import { Checkbox } from "../ui/checkbox";
import {
  MESSAGES_SLIDER_VALUES,
  MessagesNumRanges,
  messagesNumRanges,
  type MessagesNumRange,
} from "./constants/chatHistory";
import { useMemo } from "react";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import ChatHistoryContentMessagesRangeSlider from "./components/ChatHistoryContentMessagesRangeSlider";

type Props = {
  renderHeader?: React.ReactNode;
  children: React.ReactNode;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  showSearchMatches: boolean;
  totalSearchMatches: number;
  debouncedSearchValue: string;
  currentRange: DateRangeType;
  onChangeCurrentRange: (value: DateRangeType) => void;
  startDate: Date | null;
  onStartDateChange: (value: Date | null) => void;
  endDate: Date | null;
  onEndDateChange: (value: Date | null) => void;
  selectedMessagesNumRange: MessagesNumRange;
  onSelectedMessagesRangeChange: (value: MessagesNumRange) => void;
  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (value: boolean) => void;
  customMinNumOfMessages: number | null;
  onCustomMinNumOfMessagesChange: (value: number | null) => void;
  customMaxNumOfMessages: number | null;
  onCustomMaxNumOfMessagesChange: (value: number | null) => void;
  renderFooter?: () => React.ReactNode;
  renderDownloadSelect?: () => React.ReactNode;
};

export const ChatHistoryDialogContent = ({
  renderHeader = "Conversations",
  children,
  searchValue,
  onSearchValueChange,
  showSearchMatches,
  totalSearchMatches,
  debouncedSearchValue,
  currentRange,
  onChangeCurrentRange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  selectedMessagesNumRange,
  onSelectedMessagesRangeChange,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
  customMinNumOfMessages,
  onCustomMinNumOfMessagesChange,
  customMaxNumOfMessages,
  onCustomMaxNumOfMessagesChange,
  renderFooter,
  renderDownloadSelect,
}: Props) => {
  const sliderMinValue = useMemo(() => {
    return customMinNumOfMessages === null ? 0 : MESSAGES_SLIDER_VALUES.indexOf(customMinNumOfMessages);
  }, [customMinNumOfMessages]);

  const sliderMaxValue = useMemo(() => {
    return customMaxNumOfMessages === null
      ? MESSAGES_SLIDER_VALUES.length - 1
      : MESSAGES_SLIDER_VALUES.indexOf(customMaxNumOfMessages);
  }, [customMaxNumOfMessages]);

  const handleSelectedMessagesRangeChange = (value: MessagesNumRange) => {
    onSelectedMessagesRangeChange(value);

    onCustomMaxNumOfMessagesChange(null);

    if (value === MessagesNumRanges.AtLeast10) {
      onCustomMinNumOfMessagesChange(10);
    } else if (value === MessagesNumRanges.AtLeast5) {
      onCustomMinNumOfMessagesChange(5);
    } else {
      onCustomMinNumOfMessagesChange(null);
    }
  };

  const handleSliderChange = (values: number[]) => {
    const [min, max] = values;
    const minVal = MESSAGES_SLIDER_VALUES[min];
    const maxVal = MESSAGES_SLIDER_VALUES[max];

    onCustomMinNumOfMessagesChange(typeof minVal === "string" || minVal === 0 ? null : minVal);
    onCustomMaxNumOfMessagesChange(typeof maxVal === "string" ? null : maxVal);
  };

  const isScreenXl = useTailwindMediaQuery("xl");

  const isCustomMessagesRange = selectedMessagesNumRange === MessagesNumRanges.Custom;

  if (isScreenXl) {
    return (
      <>
        <DialogHeader className="h-14 px-12 py-4 font-bold text-primary-black">{renderHeader}</DialogHeader>
        <div
          className={cn("h-full w-full px-12 pt-4", {
            "pb-8": !!renderFooter,
          })}
        >
          <div
            className={cn("flex items-center justify-between pb-4", {
              "pb-9": showSearchMatches,
            })}
          >
            <div className="w-full">
              <Input
                value={searchValue}
                onChange={e => onSearchValueChange(e.target.value)}
                placeholder="Search keywords or name"
                inputClassName={cn("placeholder:text-slate-400 !pr-9 h-10")}
                handleClear={() => onSearchValueChange("")}
              />

              {showSearchMatches && (
                <ChatHistoryDialogFoundBadge
                  foundValue={totalSearchMatches}
                  searchValue={debouncedSearchValue}
                  className="absolute mt-3"
                />
              )}
            </div>
            <span className="mx-4">
              <Checkbox
                id="downvotedMessages"
                label="Downvoted only"
                checked={isOnlyDownvotedMessages}
                onCheckedChange={e => {
                  onIsOnlyDownvotedMessagesChange(e);
                }}
                labelClassName="text-nowrap"
              />
            </span>
            <div className="mx-4">
              <StatsDateRangePicker
                selectedDateRangeType={currentRange}
                onSelectedDateRangeTypeChange={onChangeCurrentRange}
                startDate={startDate}
                onStartDateChange={onStartDateChange}
                endDate={endDate}
                onEndDateChange={onEndDateChange}
                align="start"
              />
            </div>
            <div className="ml-3 mr-1">
              <Select
                onValueChange={value => {
                  handleSelectedMessagesRangeChange(value as MessagesNumRange);
                }}
                value={selectedMessagesNumRange}
              >
                <SelectTrigger id="numOfMessages" className="w-[209px]">
                  <SelectValue placeholder=""></SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {messagesNumRanges?.map(rangeType => {
                    return (
                      <SelectItem key={rangeType} value={rangeType}>
                        {rangeType}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>

            {renderDownloadSelect && renderDownloadSelect()}
          </div>

          {isCustomMessagesRange && (
            <div className="mb-12 flex w-full justify-end">
              <ChatHistoryContentMessagesRangeSlider
                className="w-full"
                value={[sliderMinValue, sliderMaxValue]}
                onSliderValueChange={handleSliderChange}
              />
            </div>
          )}

          <div
            className="flex h-full flex-1 grow flex-col gap-2 overflow-y-scroll"
            style={{
              maxHeight: `calc(100% - 98px - ${isCustomMessagesRange ? "60px" : "0px"})`,
            }}
          >
            {children}
          </div>
        </div>
        {renderFooter && <DialogFooter className="px-12">{renderFooter()}</DialogFooter>}
      </>
    );
  }

  return (
    <>
      <DialogHeader className="font-bold text-primary-black">{renderHeader}</DialogHeader>
      <div className="flex h-full flex-col gap-6 px-5 py-4 pb-20 lg:flex-row lg:px-8">
        <div className="flex min-w-52 flex-col lg:max-w-52">
          <div className="flex items-start">
            <div className="relative w-full">
              <Input
                value={searchValue}
                onChange={e => onSearchValueChange(e.target.value)}
                placeholder="Search keywords or name"
                inputClassName="placeholder:text-slate-400 !pr-9 !sm:pr-9 h-10"
                handleClear={() => onSearchValueChange("")}
              />
              {showSearchMatches && (
                <ChatHistoryDialogFoundBadge
                  foundValue={totalSearchMatches}
                  searchValue={debouncedSearchValue}
                  className="mt-1"
                />
              )}
            </div>

            {renderDownloadSelect && renderDownloadSelect()}
          </div>
          <div className="mt-4">
            <p className="mb-2 text-left text-sm font-medium text-neutral-400">Filter by</p>
            <StatsDateRangePicker
              selectedDateRangeType={currentRange}
              onSelectedDateRangeTypeChange={onChangeCurrentRange}
              startDate={startDate}
              onStartDateChange={onStartDateChange}
              endDate={endDate}
              onEndDateChange={onEndDateChange}
              align="start"
            />
          </div>

          <div className="my-4">
            <div className="mb-2 flex items-center gap-1">
              <p className="text-left text-sm font-medium text-neutral-400">Number of messages</p>
              <HybridTooltipPopover>
                <p>By moving around this slider, you will only see the conversations within that range of messages</p>
              </HybridTooltipPopover>
            </div>
            <Select
              onValueChange={value => {
                handleSelectedMessagesRangeChange(value as MessagesNumRange);
              }}
              value={selectedMessagesNumRange}
            >
              <SelectTrigger id="numOfMessages" className="w-full">
                <SelectValue placeholder=""></SelectValue>
              </SelectTrigger>
              <SelectContent>
                {messagesNumRanges?.map(rangeType => {
                  return (
                    <SelectItem key={rangeType} value={rangeType}>
                      {rangeType}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>

            {isCustomMessagesRange && (
              <ChatHistoryContentMessagesRangeSlider
                className="mt-8 pb-6"
                value={[sliderMinValue, sliderMaxValue]}
                onSliderValueChange={handleSliderChange}
              />
            )}
          </div>

          <div className="my-4">
            <Checkbox
              id="downvotedMessages"
              label="Show only chats with downvoted messages"
              checked={isOnlyDownvotedMessages}
              onCheckedChange={e => {
                onIsOnlyDownvotedMessagesChange(e);
              }}
            />
          </div>
        </div>

        <div className="flex h-full max-h-full flex-1 grow flex-col gap-2 overflow-y-scroll">{children}</div>
      </div>
      {renderFooter && <DialogFooter>{renderFooter()}</DialogFooter>}
    </>
  );
};
