import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { LinkButton } from "@/components/ui/link-button";
import { cn } from "@/lib/utils";
import { getKeyDisplayName } from "@/pages/StatsLeads/utils/statsLeadsUtils";
import type { LeadStatsHeader } from "@/types/stats";

type Props = {
  selectedTableHeaders: LeadStatsHeader[];
  setSelectedTableHeaders: React.Dispatch<React.SetStateAction<LeadStatsHeader[]>>;
  allTableHeaders: LeadStatsHeader[];
  disabled: boolean;
};

export const StatsLeadsTableColumnsPicker = ({
  selectedTableHeaders,
  setSelectedTableHeaders,
  allTableHeaders,
  disabled,
}: Props) => {
  const isNoColumnSelected = selectedTableHeaders.length === 0;
  const areAllColumnsSelected = allTableHeaders.length === selectedTableHeaders.length;

  const getHeadersDropdownTitle = () => {
    if (areAllColumnsSelected) {
      return "All columns";
    }

    return "Select columns";
  };

  const handleSelectAllColumns = () => {
    setSelectedTableHeaders(allTableHeaders);
  };

  const handleUnselectAllColumns = () => {
    setSelectedTableHeaders([]);
  };

  const toggleColumnSelection = (column: string) => {
    setSelectedTableHeaders(prev => {
      if (prev.some(header => header.key === column)) {
        return prev.filter(header => header.key !== column);
      }

      return [...prev, allTableHeaders.find(header => header.key === column)!];
    });
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        disabled={disabled}
        className="flex items-center gap-1 rounded-lg px-3 py-1 text-sm shadow-sm disabled:cursor-not-allowed disabled:opacity-50"
      >
        {getHeadersDropdownTitle()}
        <Icons.ChevronRight className="h-4 w-4 rotate-90" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => e.stopPropagation()}
        align="end"
        className="flex max-w-52 flex-col items-end gap-1 border border-primary-400 p-2.5"
      >
        <div className="mb-1 flex flex-wrap gap-1">
          {allTableHeaders?.map(header => {
            const isSelected = selectedTableHeaders.some(selectedHeader => selectedHeader.key === header.key);

            return (
              <DropdownMenuItem
                key={header.key}
                className={cn(
                  "flex cursor-pointer items-center gap-1 rounded-[4px] border border-neutral-200 bg-neutral-100 px-2 py-[2px] text-neutral-500",
                  {
                    "border-primary-400 bg-primary-100 text-neutral-600": isSelected,
                  }
                )}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleColumnSelection(header.key);
                }}
              >
                {getKeyDisplayName(header.key)}
              </DropdownMenuItem>
            );
          })}
        </div>

        {isNoColumnSelected ? (
          <LinkButton onClick={handleSelectAllColumns}>Select all</LinkButton>
        ) : (
          <LinkButton onClick={handleUnselectAllColumns}>Unselect all</LinkButton>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
