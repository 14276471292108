import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StatsQueryFilters, UserLeadsStats } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Agent } from "@/types/agent";

type GetUserLeadsStatsVariables = {
  startDate: string;
  endDate: string;
  agentsIds?: Agent["_id"][];
  search?: string;
};
export const userLeadsStats = {
  all: ["userLeadsStats"] as const,
  params: ({ startDate, endDate, agentsIds, search }: GetUserLeadsStatsVariables) =>
    [...userLeadsStats.all, startDate, endDate, [...new Set(agentsIds)], search] as const,
};

const getUserLeadsStats = async ({ startDate, endDate, agentsIds, search }: GetUserLeadsStatsVariables) => {
  const { data } = await restClient.get<UserLeadsStats>(apiPaths.getUserLeadsStats, {
    params: {
      startDate,
      endDate,
      agentsIds: agentsIds?.join(","),
      timezone: StatisticsHelpers.getTimezone(),
      search,
    },
  });
  return data;
};

type Props = Omit<StatsQueryFilters, "channels"> & {
  agentsIds?: Agent["_id"][];
  search?: string;
};

export const useGetUserLeadsStats = (props: Props) => {
  const { currentRange, startDate, endDate, enabled = true } = props;
  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: userLeadsStats.params({
      startDate: startDateParam,
      endDate: endDateParam,
      agentsIds: props.agentsIds,
      search: props.search,
    }),
    queryFn: () =>
      getUserLeadsStats({
        startDate: startDateParam,
        endDate: endDateParam,
        agentsIds: props.agentsIds,
        search: props.search,
      }),
    enabled: !!startDateParam && !!endDateParam && enabled,
  });

  return query;
};
