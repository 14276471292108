import type { OpenApiSchema } from "@/types/open-api";

export const prefillAgentId = (openApiJson: OpenApiSchema, agentId: string) => {
  if (!agentId) {
    return openApiJson;
  }

  const modifiedOpenApi = JSON.parse(JSON.stringify(openApiJson)) as OpenApiSchema;

  const agentEndpoints = ["/api/v1/access/agents/{id}/conversations", "/api/v1/access/agents/{id}/conversations/{cid}"];

  for (const path of agentEndpoints) {
    if (modifiedOpenApi.paths[path]) {
      for (const method in modifiedOpenApi.paths[path]) {
        if (modifiedOpenApi.paths[path][method].parameters) {
          (
            modifiedOpenApi.paths[path][method].parameters as {
              name: string;
              in: string;
              example?: string;
              description?: string;
            }[]
          ).forEach(param => {
            if (param.in === "path" && path.includes("agents/{id}")) {
              param.example = agentId;
            }
          });
        }

        const currentDesc = modifiedOpenApi.paths[path][method].description;
        const newNote = "\n\n**Note:** The example agent ID used is the currently selected agent.";

        if (typeof currentDesc === "string") {
          modifiedOpenApi.paths[path][method].description = currentDesc + newNote;
        } else {
          modifiedOpenApi.paths[path][method].description = newNote.trimStart();
        }
      }
    }
  }

  return modifiedOpenApi;
};
