import { useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import { ChatHistoryDialogContent } from "@/components/ChatHistoryDialog/ChatHistoryDialogContent";
import { MessagesNumRanges } from "@/components/ChatHistoryDialog/constants/chatHistory";
import { ChatHistoryConversationDialogContent } from "@/components/ChatHistoryDialog/ChatHistoryConversationDialogContent";
import { ChatHistoryDialogListItem } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogListItem";

import { useGetAllAgentsConversationsWithUser } from "@/data/queries/useGetAllAgentsConversationsWithUser";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";

import { DateRangeTypes } from "@/types/datepicker";
import type { User } from "@/types/user";
import type { DateRangeType } from "@/types/datepicker";
import type { MessagesNumRange } from "@/components/ChatHistoryDialog/constants/chatHistory";
import type { ConversationWithSearchMatches } from "@/pages/Stats/components/AgentChatHistoryDialog/AgentChatHistoryDialog";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import type { Conversation } from "@/types/conversation";
import { useGetUserConversationHistoryReport } from "@/data/queries/stats/useGetUserConversationHistoryReport";
import { IconButton } from "@/components/ui/icon-button";
import { Checkbox } from "@/components/ui/checkbox";
import ChatHistoryDialogDownloadFooter from "@/components/ChatHistoryDialog/components/ChatHistoryDialogDownloadFooter";
import { ChatHistoryDialogDownloadSelectBtn } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogDownloadSelectBtn";
import { ChatHistoryDialogLoader } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogLoader";
import { ChatHistoryDialogEmptyMessage } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogEmptyMessage";
import { Dialog, DialogContent } from "@/components/ui/dialog";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: User["_id"];
  userName: User["name"];
  initialCurrentRange?: DateRangeType;
  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (isOnlyDownvotedMessages: boolean) => void;
};

export const UserChatHistoryDialog = ({
  userId,
  userName,
  isOpen,
  onClose,
  initialCurrentRange,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");

  const [currentRange, setCurrentRange] = useState<DateRangeType>(initialCurrentRange ?? DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentConversation, setCurrentConversation] = useState<ConversationWithSearchMatches | null>(null);

  const [selectedMessagesNumRange, setSelectedMessagesNumRange] = useState<MessagesNumRange>(MessagesNumRanges.All);
  const [customMinNumOfMessages, setCustomMinNumOfMessages] = useState<number | null>(null);
  const [customMaxNumOfMessages, setCustomMaxNumOfMessages] = useState<number | null>(null);

  const [debouncedSearchValue] = useDebounceValue(searchValue, 700);
  const [debouncedCustomMinNumOfMessages] = useDebounceValue(customMinNumOfMessages, 700);
  const [debouncedCustomMaxNumOfMessages] = useDebounceValue(customMaxNumOfMessages, 700);

  const [isSelectToDownloadOpen, setIsSelectToDownloadOpen] = useState(false);
  const [selectedConversationsIds, setSelectedConversationsIds] = useState<string[]>([]);

  const { data, isLoading } = useGetAllAgentsConversationsWithUser({
    userId,
    enabled: !!userId,
    startDate,
    endDate,
    currentRange,
    minMessages: debouncedCustomMinNumOfMessages,
    maxMessages: debouncedCustomMaxNumOfMessages,
    searchValue: debouncedSearchValue,
    onlyWithDownvotes: isOnlyDownvotedMessages,
  });

  const showSearchMatches = data?.totalSearchMatches !== undefined && !!debouncedSearchValue;

  const isScreenXl = useTailwindMediaQuery("xl");

  const { downloadReport, isLoadingDownloadReport } = useGetUserConversationHistoryReport();

  const handleDownloadReport = (conversationIds: Conversation["_id"][]) => {
    void downloadReport({
      userId,
      userName,
      currentRange,
      startDate,
      endDate,
      minMessages: debouncedCustomMinNumOfMessages,
      maxMessages: debouncedCustomMaxNumOfMessages,
      searchValue: debouncedSearchValue,
      onlyWithDownvotes: isOnlyDownvotedMessages,
      conversations: conversationIds,
    });
  };

  const getIsConversationSelected = (conversationId: string) => selectedConversationsIds.includes(conversationId);
  const handleClickConversationSelect = (conversationId: string, checked: boolean) => {
    if (checked) {
      setSelectedConversationsIds(prev => [...prev, conversationId]);
    } else {
      setSelectedConversationsIds(prev => prev.filter(id => id !== conversationId));
    }
  };

  const handleLetMeSelectConversationsToDownload = () => {
    setIsSelectToDownloadOpen(prev => !prev);
    const allCurrentConversationsIds =
      data?.conversations.map(conversationItem => conversationItem.conversation._id) ?? [];
    setSelectedConversationsIds(allCurrentConversationsIds);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        onClose();
        setCurrentConversation(null);
      }}
    >
      <DialogContent variant={isScreenXl ? "xl" : "large"}>
        {currentConversation ? (
          <ChatHistoryConversationDialogContent
            currentConversation={currentConversation}
            onBackButtonPress={() => setCurrentConversation(null)}
            showSearchMatches={showSearchMatches}
            debouncedSearchValue={debouncedSearchValue}
            renderActionButtons={() => (
              <Button
                variant="tertiary"
                prefixIcon={<Icons.Download />}
                onClick={() => {
                  handleDownloadReport([currentConversation.conversation._id]);
                }}
                disabled={isLoadingDownloadReport}
                loading={isLoadingDownloadReport}
              >
                Download
              </Button>
            )}
          />
        ) : (
          <ChatHistoryDialogContent
            renderHeader={`${userName}'s conversations`}
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            showSearchMatches={showSearchMatches}
            totalSearchMatches={data?.totalSearchMatches ?? 0}
            debouncedSearchValue={debouncedSearchValue}
            currentRange={currentRange}
            onChangeCurrentRange={setCurrentRange}
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            selectedMessagesNumRange={selectedMessagesNumRange}
            onSelectedMessagesRangeChange={setSelectedMessagesNumRange}
            isOnlyDownvotedMessages={isOnlyDownvotedMessages}
            onIsOnlyDownvotedMessagesChange={onIsOnlyDownvotedMessagesChange}
            customMinNumOfMessages={customMinNumOfMessages}
            onCustomMinNumOfMessagesChange={setCustomMinNumOfMessages}
            customMaxNumOfMessages={customMaxNumOfMessages}
            onCustomMaxNumOfMessagesChange={setCustomMaxNumOfMessages}
            renderFooter={() => (
              <ChatHistoryDialogDownloadFooter
                disabled={!selectedConversationsIds.length}
                onDeselectAllClick={() => setSelectedConversationsIds([])}
                onDownloadClick={() => handleDownloadReport(selectedConversationsIds)}
                isLoadingDownload={isLoadingDownloadReport}
              />
            )}
            renderDownloadSelect={() => (
              <ChatHistoryDialogDownloadSelectBtn
                onClick={handleLetMeSelectConversationsToDownload}
                disabled={isLoading}
              />
            )}
          >
            {isLoading ? (
              <ChatHistoryDialogLoader />
            ) : data?.conversations.length === 0 ? (
              <ChatHistoryDialogEmptyMessage />
            ) : (
              data?.conversations?.map(conversationItem => {
                return (
                  <div key={conversationItem.conversation._id} className="flex items-center gap-2">
                    {isSelectToDownloadOpen && (
                      <Checkbox
                        variant="circle"
                        checked={getIsConversationSelected(conversationItem.conversation._id)}
                        onCheckedChange={checked => {
                          handleClickConversationSelect(conversationItem.conversation._id, checked);
                        }}
                      />
                    )}
                    <ChatHistoryDialogListItem
                      conversationItem={conversationItem}
                      conversationUsers={conversationItem.conversation.users}
                      onOpen={() => setCurrentConversation(conversationItem)}
                      renderHoverButtons={() => (
                        <IconButton
                          variant="tertiary"
                          className="p-0"
                          icon={<Icons.Download className="size-5" />}
                          onClick={e => {
                            e.stopPropagation();
                            handleDownloadReport([conversationItem.conversation._id]);
                          }}
                          disabled={isLoadingDownloadReport}
                        />
                      )}
                    />
                  </div>
                );
              })
            )}
          </ChatHistoryDialogContent>
        )}
      </DialogContent>
    </Dialog>
  );
};
