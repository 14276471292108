import { Dialog, DialogOverlay } from "../ui/dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";
import { IconButton } from "../ui/icon-button";
import { Icons } from "../ui/icons";
import { forwardRef } from "react";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: React.ReactNode;
};

export const SidePanel = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & Props>(
  ({ isOpen, setIsOpen, children }, ref) => {
    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogOverlay />
        <AnimatePresence>
          {isOpen && (
            <DialogPrimitive.Content forceMount asChild>
              <motion.div
                ref={ref}
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "tween", duration: 0.3, ease: "easeIn" }}
                className="fixed right-0 top-0 z-50 h-screen w-full bg-gradient-to-b from-primary-100 from-0% to-white to-[40%] px-6 pt-11 @container/main md:min-w-[600px] md:max-w-[700px]"
              >
                <IconButton
                  size="tiny"
                  variant="tertiary"
                  icon={<Icons.Close className="text-neutral-400" />}
                  className="absolute right-3 top-3 z-50"
                  onClick={() => setIsOpen(false)}
                />
                {children}
              </motion.div>
            </DialogPrimitive.Content>
          )}
        </AnimatePresence>
      </Dialog>
    );
  }
);
SidePanel.displayName = "SidePanel";
