import type { ILeadGen, LeadStatsHeader, SortByType } from "@/types/stats";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter";
import * as XLSX from "xlsx";
import {
  DEFAULT_LEAD_STATS_ICON_KEY,
  PrimaryLeadGenFieldsMap,
  StaticTableHeaders,
  StatsLeadsKeysToOmit,
} from "../constants/StatsLeadsConstants";

export const normalizeString = (str: string): string => {
  return str.toLowerCase().replace(/[^a-z0-9]/g, "");
};

export const getKeyDisplayName = (key: string): string => {
  const name = key.toLowerCase().replace(/[^a-z0-9]/g, " ");
  return capitalizeFirstLetter(name);
};

export const getMappedKey = (backendKey: string): LeadStatsHeader => {
  const normalizedBackendKey = normalizeString(backendKey);

  for (const field of PrimaryLeadGenFieldsMap) {
    if (normalizeString(field.key) === normalizedBackendKey) {
      return field;
    }
  }

  return {
    key: backendKey,
    iconKey: DEFAULT_LEAD_STATS_ICON_KEY,
  };
};

export const getGridTemplateColumns = (columns: number) => {
  const minMaxColumns = Array.from({ length: columns }, (_, index) => {
    if (index === 0) {
      return "auto";
    }
    return `minmax(190px, ${1}fr)`;
  }).join(" ");
  return minMaxColumns;
};

export const getIconForKey = (key: string) => {
  const icon = PrimaryLeadGenFieldsMap.find(field => normalizeString(field.key) === normalizeString(key));

  return icon ? icon.iconKey : DEFAULT_LEAD_STATS_ICON_KEY;
};

export const getHeaders = (leadsData: ILeadGen[]) => {
  const allKeysRaw = [...new Set(leadsData.flatMap(lead => lead.fields.map(field => field.key)))];

  const keysFiltered = allKeysRaw.filter(key => {
    return !StatsLeadsKeysToOmit.includes(key);
  });

  const keysSortedByPrimaryFieldsAppearance = keysFiltered.sort((a, b) => {
    const indexA = PrimaryLeadGenFieldsMap.findIndex(item => item.key === a);
    const indexB = PrimaryLeadGenFieldsMap.findIndex(item => item.key === b);

    if (indexA === -1 && indexB === -1) {
      return 0;
    } else if (indexA === -1) {
      return 1;
    } else if (indexB === -1) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });

  const keysWithIcons = keysSortedByPrimaryFieldsAppearance.map(key => {
    return {
      key,
      iconKey: getIconForKey(key),
    };
  });

  return keysWithIcons;
};

export const getLeadFieldValue = ({ lead, key }: { lead: ILeadGen; key: string }) => {
  return lead.fields.find(field => field.key === key)?.value;
};

export const sortLeadsByFieldsValues = ({ leadsData, sortBy }: { leadsData: ILeadGen[]; sortBy: SortByType }) => {
  if (!sortBy) {
    return leadsData;
  }

  const sortedLeadsData = [...leadsData].sort((a, b) => {
    const valueA = getLeadFieldValue({ lead: a, key: sortBy.key }) ?? "";
    const valueB = getLeadFieldValue({ lead: b, key: sortBy.key }) ?? "";

    if (sortBy.order === "ASC") {
      return valueA.localeCompare(valueB);
    }

    return valueB.localeCompare(valueA);
  });

  return sortedLeadsData;
};

export const sortLeadsByAgent = ({ leadsData, sortBy }: { leadsData: ILeadGen[]; sortBy: SortByType }) => {
  if (!sortBy) {
    return leadsData;
  }

  const sortedLeadsData = [...leadsData].sort((a, b) => {
    const valueA = a.agent.name;
    const valueB = b.agent.name;

    if (sortBy.order === "ASC") {
      return valueA.localeCompare(valueB);
    }

    return valueB.localeCompare(valueA);
  });

  return sortedLeadsData;
};

export const sortLeadsByDate = ({ leadsData, sortBy }: { leadsData: ILeadGen[]; sortBy: SortByType }) => {
  if (!sortBy) {
    return leadsData;
  }

  const sortedLeadsData = [...leadsData].sort((a, b) => {
    const valueA = a.createdAt;
    const valueB = b.createdAt;

    if (sortBy.order === "ASC") {
      return valueA.localeCompare(valueB);
    }

    return valueB.localeCompare(valueA);
  });

  return sortedLeadsData;
};

export const downloadLeadsData = ({
  leads,
  selectedHeaders,
  selectedRows,
}: {
  leads: ILeadGen[];
  selectedHeaders: LeadStatsHeader[];
  selectedRows: Set<string>;
}) => {
  const data = leads.filter(lead => selectedRows.has(lead._id));

  const headers = ["Agent", "Date created", ...selectedHeaders.map(header => getKeyDisplayName(header.key))];

  const preparedData = data?.map(lead => {
    const row: Record<string, string> = {
      agent: lead.agent.name,
      date: new Date(lead.createdAt).toLocaleDateString(),
    };

    selectedHeaders.forEach(header => {
      row[header.key] = getLeadFieldValue({ lead, key: header.key }) ?? "";
    });

    return row;
  });

  const worksheet = XLSX.utils.json_to_sheet(preparedData);

  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

  const csv = XLSX.utils.sheet_to_csv(worksheet);

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "leads.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getSortedLeads = ({
  leads,
  sortBy,
  selectedTableHeaders,
}: {
  leads: ILeadGen[];
  sortBy: SortByType;
  selectedTableHeaders: LeadStatsHeader[];
}) => {
  const fieldHeader = selectedTableHeaders.find(header => header.key === sortBy?.key);

  let newLeads = [...leads];

  if (sortBy?.key === StaticTableHeaders.AGENT) {
    newLeads = sortLeadsByAgent({
      leadsData: leads ?? [],
      sortBy,
    });
  } else if (sortBy?.key === StaticTableHeaders.DATE) {
    newLeads = sortLeadsByDate({
      leadsData: leads ?? [],
      sortBy,
    });
  } else if (fieldHeader) {
    newLeads = sortLeadsByFieldsValues({
      leadsData: leads ?? [],
      sortBy,
    });
  }

  return newLeads;
};
