import { cn } from "@/lib/utils";

type Props = {
  text: string;
  className?: string;
};

export const ChatHistoryPreviewConversationSummary = ({ text, className }: Props) => {
  return (
    <div className={cn("flex-1 rounded bg-gradient-to-r from-[#BDA7FF] via-[#C3AFFF] to-[#EDD3B9] p-px", className)}>
      <div className="rounded bg-white">
        <div className="flex-1 truncate whitespace-nowrap bg-gradient-to-r from-[#BDA7FF1A] via-[#C3AFFF1A] to-[#EDD3B91A] p-1 px-3 text-xs">
          {text}
        </div>
      </div>
    </div>
  );
};
