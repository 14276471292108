import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import type { ExternalAgent, WhatsAppAgent } from "@/types/agent";
import { externalAgentsKeys } from "@/data/queries/useGetExternalAgents";
import type {
  WhatsAppMessageTemplate,
  CreateTemplateProp,
  CreateTemplatesResponse,
  WaPayload,
  DeleteTemplateProp,
} from "@/types/integrations/whatsapp";

type Response = {
  message: string;
  externalAgent: WhatsAppAgent;
};

type GetTemplatesResponse = {
  data: WhatsAppMessageTemplate[];
};

const createWhatsAppAgent = async (payload: WaPayload) => {
  const { data } = await restClient.post<Response, AxiosResponse<Response>, WaPayload>(
    apiPaths.createWhatsAppAgent,
    payload
  );
  return data;
};

export const getWhatsAppAgentMessageTemplates = async (phoneNumberId: string) => {
  const { data } = await restClient.get<GetTemplatesResponse>(apiPaths.getWhatsAppAgentMessageTemplates(phoneNumberId));
  return data;
};

export const createWhatsAppAgentMessageTemplates = async (phoneNumberId: string, payload: CreateTemplateProp) => {
  const { data } = await restClient.post<CreateTemplatesResponse>(
    apiPaths.postCreateWhatsAppAgentMessageTemplates(phoneNumberId),
    { payload }
  );
  return data;
};

export const deleteWhatsAppAgentMessageTemplates = async (phoneNumberId: string, payload: DeleteTemplateProp) => {
  const data = await restClient.post(apiPaths.deleteWhatsAppAgentMessageTemplates(phoneNumberId), {
    payload,
  });
  return data;
};

export const useCreateWhatsAppAgent = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Response, AxiosError<Response>, WaPayload>({
    mutationFn: createWhatsAppAgent,
    onSuccess: ({ externalAgent }, { agentId }) => {
      queryClient.setQueryData<ExternalAgent[]>(externalAgentsKeys.id(agentId), prev => {
        if (!prev) {
          return [externalAgent];
        }
        return [...prev, externalAgent];
      });
      toast.success("WhatsApp register ID created successfully.");
    },
  });

  return mutation;
};
