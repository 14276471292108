import { useContext } from "react";
import { MessageVoiceContext } from "./MessageVoiceContext";

export const useMessageVoice = () => {
  const context = useContext(MessageVoiceContext);
  if (!context) {
    throw new Error("useMessageVoice must be used within a MessageVoiceProvider");
  }
  return context;
};
