import type { Conversation } from "@/types/conversation";
import type { Agent, AgentMode } from "@/types/agent";
import { apiPaths } from "./apiPaths";
import { restClient } from "./initAxios";

export const createConversation = async (
  agentId: Agent["_id"],
  { isPreview, type }: { isPreview?: boolean; type?: AgentMode } = {}
) => {
  const { data } = await restClient.post<Conversation>(apiPaths.createConversation(agentId, { isPreview, type }));
  return data;
};

export const createGroupConversation = async (agentIds: Agent["_id"][]) => {
  const { data } = await restClient.post<Conversation>(apiPaths.createGroupConversation, { agents: agentIds });
  return data;
};
