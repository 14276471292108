import noAgentImg from "@/assets/images/NoAgent.svg";

export const ChatHistoryDialogEmptyMessage = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <img src={noAgentImg} alt="No Agent Found" className="w-60" />
      <p className="mt-5 max-w-[500px] text-center text-sm font-medium text-neutral-400">
        There is no data available right now. Create your agent, and you will start seeing conversations here.
      </p>
    </div>
  );
};
