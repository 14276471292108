import { useEffect, useRef } from "react";
import "rapidoc";

import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useGetOpenApi } from "@/data/queries/useGetOpenApi";
import { filterOpenApiEndpoints } from "./utils/filterOpenApiEndpoints";
import { useAgentFormContext } from "../../hooks/useAgentFormContext";
import { prefillAgentId } from "./utils/prefillAgentId";
import { prefillConversationId } from "./utils/prefillConversationId";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { filterApiKey } from "./utils/filterApiKey";
import { prefillApiUrl } from "./utils/prefillApiUrl";
import { addCodeSamples } from "./utils/addCodeSamples";
import { fixRapiDocStyles } from "./utils/fixRapiDocStyles";
import type { OpenApiSchema } from "@/types/open-api";

const twpPrimary400 = "#7844D3";
const fullDocs = "https://docs.agentx.so/docs/getting-started";

export const ApiExplorer = () => {
  const { data, isLoading, isPending, isError } = useGetOpenApi();
  const form = useAgentFormContext();
  const [agentId] = form.getValues(["_id", "publishedToCommunity"]);
  const { conversationId } = useChatContext();
  const { isMobile } = useMediaQueriesContext();
  const rapiDocRef = useRef<{ loadSpec: (spec: OpenApiSchema) => void } | null>(null);
  const { user } = useGetUser();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (rapiDocRef.current && data) {
      let modifiedOpenApi = filterOpenApiEndpoints(data);
      modifiedOpenApi = prefillApiUrl(modifiedOpenApi);
      modifiedOpenApi = prefillAgentId(modifiedOpenApi, agentId);
      modifiedOpenApi = prefillConversationId(modifiedOpenApi, conversationId, isMobile);
      modifiedOpenApi = filterApiKey(modifiedOpenApi);
      modifiedOpenApi = addCodeSamples(modifiedOpenApi, user?.apiKey || "YOUR_API_KEY");

      rapiDocRef.current.loadSpec(modifiedOpenApi);
    }
  }, [data, agentId, conversationId, isMobile, token, user?.apiKey]);

  useEffect(() => {
    const styleOptions = {
      titleColor: twpPrimary400,
      borderColorHover: twpPrimary400,
      headerBorderColorExpanded: twpPrimary400,
    };

    const observer = fixRapiDocStyles(styleOptions);
    return () => {
      observer.disconnect();
    };
  });

  if (isLoading || isPending) {
    return <LoadingSpinner className="flex items-center justify-center pt-4" />;
  }

  if (isError) {
    return (
      <div className="p-10">
        <p>There was an error when retrieving API documentation.</p>
      </div>
    );
  }

  return (
    <rapi-doc
      custom-css="
        .param-name {
          margin: 12px !important;
          color: red !important;
        }
      "
      primary-color={twpPrimary400}
      allow-authentication="false"
      allow-server-selection="false"
      allow-spec-file-load="false"
      allow-spec-url-load="false"
      allow-try="false"
      api-key-location="header"
      api-key-name="x-api-key"
      api-key-value={user?.apiKey || ""}
      code-samples="true"
      collapse-path="true"
      default-schema-tab="schema"
      default-theme="light"
      ref={rapiDocRef}
      render-style="view"
      show-header="false"
      show-method-in-nav-bar="as-colored-block"
      theme="light"
    >
      <div slot="footer" className="mx-4 my-4 text-xl">
        <a href={fullDocs} target="_blank" rel="noreferrer" className="text-primary-400 hover:underline">
          View full documentation
        </a>
      </div>
    </rapi-doc>
  );
};
