import type { TypeFromConstObject } from "./types";

export enum ExternalAgentPlatform {
  DISCORD = "discord",
  SLACK = "slack",
  TELEGRAM = "telegram",
  TWILIO = "twilio",
  WHATSAPP = "whatsapp",
  INSTAGRAM = "instagram",
  MESSENGER = "messenger",
}

export const Origins = {
  WEB: "web",
  API: "api",
  DISCORD: ExternalAgentPlatform.DISCORD,
  SLACK: ExternalAgentPlatform.SLACK,
  TELEGRAM: ExternalAgentPlatform.TELEGRAM,
  TWILIO: ExternalAgentPlatform.TWILIO,
  WHATSAPP: ExternalAgentPlatform.WHATSAPP,
  INSTAGRAM: ExternalAgentPlatform.INSTAGRAM,
  MESSENGER: ExternalAgentPlatform.MESSENGER,
  ECOMMERCE: "ecommerce",
  EMBEDDED: "embedded",
  WORKSPACE: "workspace",
  AGENT_SPACE: "agent_space",
  OTHER: "other",
} as const;
export type Origin = TypeFromConstObject<typeof Origins>;
